import React from "react";
import { Result } from "antd";

const FunnelAnalysis: React.FC = () => (
  <div>
    <Result title="Funnel Analysis" subTitle="This page is under implement. Please come back later." />
  </div>
);

export default FunnelAnalysis;
