export enum ParamType {
  String = "string",
  Number = "number",
  Boolean = "boolean",
}

export const paramOpts = Object.entries(ParamType).map((e) => {
  return {
    value: e[1],
    label: e[0],
  };
});

export const groupProductOps = [
  {
    value: "live-ops",
    label: "LiveOps",
  },
  {
    value: "rock",
    label: "Rock",
  },
  {
    value: "hs",
    label: "HS",
  },
  {
    value: "gs",
    label: "GS",
  },
];
