import { booleanOperators } from "@src/components/query-builder/v2/member-group-filter-explore";

export function serialize(filter: any): any {
  const logical_operator = filter.combinator;
  const nested_filters = filter.rules;
  const serialized_filters = nested_filters.map((nested_filter: any) => {
    if (nested_filter["combinator"]) {
      return serialize(nested_filter);
    }
    return {
      member: nested_filter.field,
      operator: nested_filter.operator,
      values: !Array.isArray(nested_filter.value) ? [nested_filter.value] : nested_filter.value,
    };
  });
  return {
    [logical_operator]: serialized_filters,
  };
}

export function deserialize(filter: any): any {
  const logical_operator = filter.and || filter.or || [];
  const serialized_filters = logical_operator.map((nested_filter: any) => {
    if (nested_filter["or"] || nested_filter["and"]) {
      return deserialize(nested_filter);
    }
    return {
      field: nested_filter.member,
      operator: nested_filter.operator,
      value: nested_filter.values,
    };
  });
  return {
    combinator: filter.or ? "or" : "and",
    rules: serialized_filters,
  };
}

export const getFields = (rules: any[]) => {
  const fields: string[] = [];
  rules.forEach((item) => {
    if (item.rules?.length > 0) {
      const _fields = getFields(item.rules);
      fields.push(..._fields);
    } else {
      fields.push(item.field);
    }
  });

  return fields;
};

const checkField = (rules: any[]) => {
  const result: boolean[] = [];

  rules.forEach((item) => {
    if (item.rules?.length > 0) {
      const _result = checkField(item.rules);
      result.push(..._result);
    } else {
      const value = item.value?.toString();
      const isValid = booleanOperators.includes(item.operator)
        ? item.field && item.operator
        : item.field && item.operator && value;
      if (Array.isArray(isValid)) {
        result.push(isValid.length > 0);
      }

      result.push(isValid);
    }
  });
  return result;
};

export const checkValidQuery = (rules: any[]) => {
  return checkField(rules).every((item) => item);
};
