import React from "react";
import { Button, theme, Form, Input, Popconfirm, Radio } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useCreate } from "@refinedev/core";
import clsx from "clsx";

// config
import { ADAPTER_QUERY_BUILDER, DEFAULT_QUERY_BUILDER } from "@src/config/query-builder";

// components
import DbtExplorer from "./components/dbt-explorer";
import WareHouseExplorer from "./components/warehouse-explorer";

// adapter
import { MultiStepAdapter } from "@src/adapter";

// context
import { useMultiStepQuery } from "@src/contexts/multi-step-query-context";
import { useAppContext } from "@src/contexts/app-context";
import { useWarehouseContext } from "@src/contexts/warehouse-context";

// helpers
import { checkDuplicateName } from "@src/helpers/multi-step-query/check-duplicate-name";

// types
import { IExplorerNode } from "@src/types/explorer";

// hooks
import { useResizeQuerySidebar } from "@src/hooks/multi-step-query/use-resize-query-sidebar";

const multiStepAdapter = new MultiStepAdapter();

function QuerySidebar() {
  const { token } = theme.useToken();
  // contexts
  const { product } = useAppContext();
  const {
    updateMethodsNode,
    collapseQuerySidebar,
    updateMethodsQuerySidebar,
    nodeType,
    nodeBaseItem,
    explorerNode,
    updateNodeType,
    openCollapseVisualization,
    visualization,
  } = useMultiStepQuery();
  const { query, updateQuery, isQueryPresent, error } = useWarehouseContext();

  // refs
  const resizerHandlerRef = React.useRef<HTMLDivElement>(null);
  const querySidebarRef = React.useRef<HTMLDivElement>(null);
  // resize handler hooks
  useResizeQuerySidebar({ querySidebarRef, resizerHandlerRef, isOpen: collapseQuerySidebar.isOpen });

  // form
  const alias = collapseQuerySidebar.type === "edit" ? explorerNode?.alias : ``;
  const [form] = Form.useForm();
  const initialValues = {
    name: "",
    sql: "",
    alias: "",
  };

  // initialize
  React.useEffect(() => {
    form.resetFields(); // reset form

    // initialize form
    const { alias: formAlias, name: formName, sql } = multiStepAdapter.getFormNode(explorerNode);
    form.setFieldValue("alias", formAlias);
    form.setFieldValue("name", formName || "untitled");
    form.setFieldValue("sql", sql);
    // initialize query builder
    if (explorerNode?.adapter_name === ADAPTER_QUERY_BUILDER.cube.value) {
      updateQuery(explorerNode?.model_config?.json_query || DEFAULT_QUERY_BUILDER);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [explorerNode]);

  const { mutateAsync: mutateCreateNode, isLoading: isLoadingCreateNode } = useCreate();
  const { mutateAsync: mutateRunQuery, isLoading: isLoadingRunQuery } = useCreate();

  // form builder
  const renderFormBuilder = () => {
    switch (nodeType) {
      case ADAPTER_QUERY_BUILDER.cube.value:
        return <WareHouseExplorer />;
      case ADAPTER_QUERY_BUILDER["dbt-like"].value:
        return <DbtExplorer form={form} />;
      default:
        return <></>;
    }
  };

  async function runQuery() {
    // TODO: fake realtime dry-run when user type sql and call api run query (debounce)
    const queryBuilder = form.getFieldValue("sql") || query;
    const alias = form.getFieldValue("alias");

    if (!alias) {
      form.validateFields();
      return;
    }

    const { configs } = multiStepAdapter.getBodyConfigs({
      nodeBaseItem,
      alias: explorerNode?.alias || "",
      nodeType: explorerNode?.adapter_name || "",
      query: queryBuilder,
    });

    const queryNodeBase = Object.values(nodeBaseItem || {});
    const { baseQuery } = multiStepAdapter.getBodyData({
      alias,
      queryNodeBase,
      product_code: product?.productCode || "",
      cubeQuery: query,
      sqlQuery: form.getFieldValue("sql"),
      nodeType,
    });

    // allow run query if this node not exist
    const payloadConfigs = JSON.parse(JSON.stringify(configs || {}));
    if (!payloadConfigs[alias]) {
      payloadConfigs[alias] = baseQuery;
    }

    const payload = {
      configs: Object.values(payloadConfigs || {}),
      target_aliases: [alias],
    };

    await mutateRunQuery({
      resource: `multistep_query/run`,
      values: payload,
      successNotification: (res) => {
        const dataSource = res?.data.results[0].data;
        const columns = (res?.data.results[0].meta.schema || []).map((item: any) => ({
          title: item.name,
          dataIndex: item.name,
          key: item.name,
        }));
        updateMethodsNode.setVisualization({
          dataSource,
          columns,
        });

        openCollapseVisualization(true);
        return {
          type: "success",
          message: "Run successfully!",
        };
      },
      errorNotification: () => {
        return {
          type: "error",
          message: `Run Failed!`,
        };
      },
    });
  }

  async function onFinish(values: any) {
    const alias = values.alias;
    let sourceNodeBase = nodeBaseItem || {};
    const clonedNodeBase = JSON.parse(JSON.stringify(sourceNodeBase));

    if (explorerNode && collapseQuerySidebar.type === "edit") {
      delete clonedNodeBase[explorerNode.alias];
      sourceNodeBase = clonedNodeBase;
    }
    const queryNodeBase = Object.values(sourceNodeBase);

    const { bodyData } = multiStepAdapter.getBodyData({
      alias,
      queryNodeBase,
      product_code: product?.productCode || "",
      cubeQuery: {
        ...query,
        dimensions: query?.dimensions || [],
      },
      sqlQuery: values.sql,
      nodeType,
    });

    // call api create node
    await mutateCreateNode({
      resource: `multistep_query/dryrun`,
      values: bodyData,
      successNotification: (res) => {
        // handle show error message
        if (
          (res?.data?.results.length === 0 && Object.keys(res?.data.errors || {}).length > 0) ||
          res?.data?.results[0].ex
        ) {
          return {
            type: "error",
            message: `Created Failed!`,
          };
        }
        const nodeItem: IExplorerNode[] = res?.data?.results || [];
        if (nodeItem.length === 0) {
          return {
            type: "error",
            message: `Node empty!`,
          };
        }

        // add node in reat flow
        const { nodeBaseItem, nodeGeneratedItem } = multiStepAdapter.getResultDryrun({
          node: nodeItem[0],
          name: values.name,
        });
        updateQuery(
          {
            ...query,
            dimensions: query?.dimensions || [],
          } || DEFAULT_QUERY_BUILDER,
        );
        updateMethodsNode.add(nodeBaseItem, nodeGeneratedItem);

        // reset
        closeQuerySidebar();

        return {
          type: "success",
          message: "Created successfully!",
        };
      },
      errorNotification: () => {
        return {
          type: "error",
          message: `Created Failed!`,
        };
      },
    });
  }

  function closeQuerySidebar() {
    updateQuery(DEFAULT_QUERY_BUILDER);
    form.resetFields();
    updateMethodsQuerySidebar.close();
    updateMethodsNode.setNode(null);
    form.setFieldValue("alias", alias);
    updateMethodsNode.setVisualization(null);
  }

  const isDisableOkButton =
    nodeType === ADAPTER_QUERY_BUILDER.cube.value ? !isQueryPresent || Boolean(error?.message) : false;

  return (
    <div
      ref={querySidebarRef}
      className={clsx(
        "border-[#E0E0E0] dark:border-[#2b2b2b] border-0 border-l-[1px] border-solid  h-[calc(100vh-89px)] shrink-0 overflow-hidden",
      )}
      style={{
        backgroundColor: token.colorBgElevated,
        width: collapseQuerySidebar.isOpen ? "500px" : "50px",
      }}
    >
      <div
        className={clsx(
          "flex items-center justify-center border-[#E0E0E0] dark:border-[#2b2b2b] border-0 border-b-[1px] border-solid py-2",
          collapseQuerySidebar.isOpen ? "hidden" : "block",
        )}
      >
        <Button type="text" size="small" onClick={() => updateMethodsQuerySidebar.open(!collapseQuerySidebar.isOpen)}>
          <LeftOutlined
            style={{
              color: token.colorPrimary,
            }}
          />
        </Button>
      </div>

      <div className={clsx("relative h-full", collapseQuerySidebar.isOpen ? "block" : "hidden")}>
        {/* resize handle */}
        <div
          className={clsx(
            "absolute left-0 top-0 right-0 bottom-0 w-[10px] z-10",
            !!visualization && "hidden", // hide resize handle when visualization is open
          )}
        >
          <div
            id="resize-handle-sidebar"
            ref={resizerHandlerRef}
            className="w-[24px] h-[32px] absolute top-1/2 -mt-[12px] -left-[12px] cursor-ew-resize"
          >
            <div className="w-[3px] h-[22px] absolute top-1/2 left-1/2 -mt-[12px]  bg-[#80868b] text-[#80868b]" />
          </div>
        </div>
        <Form form={form} initialValues={initialValues} onFinish={onFinish} className="flex flex-col justify-between">
          <div className="flex items-center border-[#E0E0E0] dark:border-[#2b2b2b] border-0 border-b-[1px] border-solid p-2">
            <Button
              type="text"
              size="small"
              onClick={() => updateMethodsQuerySidebar.open(!collapseQuerySidebar.isOpen)}
            >
              {collapseQuerySidebar.isOpen ? (
                <RightOutlined
                  style={{
                    color: token.colorPrimary,
                  }}
                />
              ) : (
                <LeftOutlined
                  style={{
                    color: token.colorPrimary,
                  }}
                />
              )}
            </Button>
            <div className="flex items-center justify-between w-full">
              <div className="font-bold">{collapseQuerySidebar.type === "create" ? "Create" : "Edit"} Node</div>
              <Radio.Group
                disabled={collapseQuerySidebar.type === "edit"}
                size="small"
                value={nodeType}
                onChange={(e) => updateNodeType(e.target.value)}
              >
                {Object.keys(ADAPTER_QUERY_BUILDER).map((key, index) => (
                  <Radio.Button key={index} value={ADAPTER_QUERY_BUILDER[key].value}>
                    {ADAPTER_QUERY_BUILDER[key].label}
                  </Radio.Button>
                ))}
              </Radio.Group>
            </div>
          </div>

          {/* query builder */}
          <div className="overflow-auto h-[calc(100vh-182px)] px-2">
            <div className="p-2">
              <Form.Item
                label="Alias"
                name="alias"
                className="mb-2"
                rules={[
                  {
                    required: true,
                    validator: async (_, value) => {
                      let nodes = updateMethodsNode.getNodeBaseConfig();
                      const clonedNodes = JSON.parse(JSON.stringify(nodes));
                      const pattern = new RegExp("^[a-zA-Z0-9_]*$");
                      if (!pattern.test(value)) {
                        return Promise.reject(new Error("Only allow input text, number and underscore!"));
                      }
                      if (collapseQuerySidebar.type === "edit") {
                        delete clonedNodes[explorerNode?.alias || ""];
                        nodes = clonedNodes;
                      }
                      const isDuplicate = checkDuplicateName(value, nodes);

                      if (isDuplicate) {
                        return Promise.reject(new Error("Name is duplicated!"));
                      }

                      if (!value) {
                        return Promise.reject(new Error("Please input name!"));
                      }
                    },
                  },
                ]}
              >
                <Input disabled={collapseQuerySidebar.type === "edit"} />
              </Form.Item>
              <Form.Item label="Name" name="name" className="mb-2">
                <Input placeholder="Please input alias name" />
              </Form.Item>
            </div>
            {renderFormBuilder()}
          </div>

          {/* run query */}
          <div className="text-right  flex items-center justify-between border-[#E0E0E0] dark:border-[#2b2b2b] border-0 border-t-[1px] pt-2 px-2 border-solid">
            <Button onClick={runQuery} loading={isLoadingRunQuery} disabled={isDisableOkButton}>
              Run Query
            </Button>
            <div>
              <Button onClick={closeQuerySidebar} className="mr-2">
                Cancel
              </Button>
              <Popconfirm
                title="Config will be saved!"
                description="Are you sure to save this config?"
                onConfirm={() => form.submit()}
                okText="Yes"
                showCancel={false}
              >
                <Button type="primary" disabled={isDisableOkButton} loading={isLoadingCreateNode}>
                  {collapseQuerySidebar.type === "create" ? "Create" : "Update"}
                </Button>
              </Popconfirm>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default QuerySidebar;
