import React, { PropsWithChildren } from "react";
import { createContext, useContext } from "react";
import { QueryBuilder, QueryBuilderRenderProps } from "@cubejs-client/react";
import cubejs from "@cubejs-client/core";
import { useCustom, useParsed } from "@refinedev/core";
import { config } from "@src/config";
import { IProduct } from "@src/types/user";
import { Spin } from "antd";
import { DEFAULT_QUERY_BUILDER } from "@src/config/query-builder";
import { useAppContext } from "./app-context";
import { useGetExplorations } from "@src/hooks/use-get-explorations";
import { getModeOfLocation } from "@src/util/fb-exploration/get-mode-of-location";

export const ExploreContext = createContext({} as any & ExploreContextProviderProps);

type IProps = {
  children: React.ReactNode;
};
interface QueryBuilderRenderInternalProps {
  restProps: QueryBuilderRenderProps;
}
interface ExploreContextProviderProps {
  setProduct: React.Dispatch<React.SetStateAction<IProduct | undefined>>;
  product?: IProduct;
  cubeToken?: string;
}
const ExploreProvider: React.FC<PropsWithChildren<ExploreContextProviderProps & QueryBuilderRenderInternalProps>> = ({
  restProps,
  setProduct,
  product,
  children,
  cubeToken,
}) => {
  const value = React.useMemo(() => {
    return { ...restProps, setProduct, product, cubeToken };
  }, [restProps, setProduct, product, cubeToken]);
  return <ExploreContext.Provider value={value}>{children}</ExploreContext.Provider>;
};

export const ExploreContextProvider: React.FC<IProps> = ({ children }) => {
  const { id } = useParsed();
  const { isEditMode, isViewMode } = getModeOfLocation();

  const [product, setProduct] = React.useState<IProduct>();
  const productCode = product?.productCode ?? config.DEFAULT_PRODUCT_CODE;

  const { products } = useAppContext();
  const { exploration, isLoading: isLoadingGetInitValue } = useGetExplorations(id as string);

  React.useEffect(() => {
    if (isLoadingGetInitValue) return;
    if (!exploration) return;

    const product = products.find((product) => product.productCode === exploration?.productCode);
    if (product) {
      setProduct(product);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exploration, isLoadingGetInitValue]);

  const { data, isLoading: isLoadingGenerateToken } = useCustom({
    url: `${config.BACKEND_URL}/product/${productCode}/generate-cube-token`,
    method: "get",
    queryOptions: {
      retry: 0,
      enabled: isEditMode ? Boolean(product?.code) : true,
    },
    errorNotification: () => {
      return {
        type: "error",
        message: "There was an error fetching data",
        description: "Error",
      };
    },
  });

  const CUBE_TOKEN = data?.data?.jwt_token;
  const isHasConfigExplore = isEditMode || isViewMode;

  const isLoading = isLoadingGenerateToken || (isHasConfigExplore && isLoadingGetInitValue);

  if (productCode && isLoading) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <Spin />
      </div>
    );
  }
  const cubejsApi = cubejs(CUBE_TOKEN, { apiUrl: config.CUBE_URL });
  const defaultQuery = isHasConfigExplore ? exploration?.vizState.query : DEFAULT_QUERY_BUILDER;
  const queryBuilderKey = isHasConfigExplore ? CUBE_TOKEN + id : CUBE_TOKEN;
  return (
    <QueryBuilder
      key={queryBuilderKey}
      cubeApi={cubejsApi}
      defaultChartType="table"
      disableHeuristics
      defaultQuery={defaultQuery as any}
      wrapWithQueryRenderer={false}
      render={(restProps) => {
        return (
          <ExploreProvider setProduct={setProduct} restProps={restProps} product={product} cubeToken={CUBE_TOKEN}>
            {children}
          </ExploreProvider>
        );
      }}
    />
  );
};

export const useExploreContext = () => useContext(ExploreContext);
