import React from "react";

import { CartesianGrid, AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend } from "recharts";
import { Col, Statistic, Row, Spin, Table, Empty, Tag } from "antd";
import { useColorContext } from "@src/contexts/color-context";
import { blue, red, green, lime, cyan, purple, magenta } from "@ant-design/colors";
import { config } from "@src/config";
import { uniqueId } from "lodash";
import { NivoBarChart } from "./nivo-charts/nivo-bar-chart";
import { NivoLineChart } from "./nivo-charts/nivo-line-chart";
import { NivoPieChart } from "./nivo-charts/nivo-pie-chart";

function getColor(i: any, mode: string) {
  const colorPalettes = [blue, red, green, lime, cyan, purple, magenta];
  const selectedColorPalette = colorPalettes[i % colorPalettes.length];

  //todo: handle dark mode
  console.log("mode", mode);

  const selectedToneIndex = 4 + Math.floor(i / (colorPalettes.length - 4));
  return selectedColorPalette[selectedToneIndex];
}

const numberFormatter = (item: string) => item.toLocaleString();

const CartesianChart = ({ resultSet, children, pivotConfig, ChartComponent }: any) => {
  return (
    <div className="p-4 h-full">
      <ResponsiveContainer width="100%" height="100%" debounce={1000}>
        <ChartComponent
          data={resultSet.chartPivot(pivotConfig)}
          margin={{
            top: 10,
            right: 40,
            left: 40,
            bottom: 0,
          }}
        >
          <XAxis axisLine={false} tickLine={false} dataKey="x" minTickGap={20} />
          <XAxis axisLine={false} tickLine={false} dataKey="y" minTickGap={20} />

          <YAxis axisLine={false} tickLine={false} tickFormatter={numberFormatter} />
          <Tooltip formatter={numberFormatter} />
          <CartesianGrid />
          {children}
          <Legend />
        </ChartComponent>
      </ResponsiveContainer>
    </div>
  );
};

const TypeToChartComponent: any = {
  line: ({ resultSet, pivotConfig }: any) => {
    const dataSource = resultSet.series(pivotConfig) || [];
    const _dataSource = dataSource.map((d: any) => {
      return {
        id: d.key,
        data: (d.series || []).map((s: any) => {
          return {
            x: s.x,
            y: s.value,
          };
        }),
      };
    });
    return <NivoLineChart dataSource={_dataSource} />;
  },
  bar: ({ resultSet, pivotConfig }: any) => {
    const dataSource = resultSet.chartPivot(pivotConfig) || [];
    const keys = (resultSet.seriesNames(pivotConfig) || []).map((e: any) => e.key);
    return <NivoBarChart dataSource={dataSource} keys={keys} />;
  },
  pie: ({ resultSet, pivotConfig }: any) => {
    const dataSource = resultSet.chartPivot(pivotConfig) || [];
    const keys = resultSet.seriesNames(pivotConfig) || [];
    const _dataSource = dataSource.map((d: any) => {
      return {
        id: d.x,
        value: d[keys[0].key],
      };
    });
    return <NivoPieChart dataSource={_dataSource} />;
  },
  area: ({ resultSet, pivotConfig, mode }: any) => (
    <CartesianChart resultSet={resultSet} pivotConfig={pivotConfig} ChartComponent={AreaChart}>
      {resultSet.seriesNames().map((series: any, i: number) => (
        <Area
          key={series.key}
          stackId="a"
          dataKey={series.key}
          name={series.title}
          stroke={getColor(i, mode)}
          fill={getColor(i, mode)}
        />
      ))}
    </CartesianChart>
  ),
  number: ({ resultSet }: any) => (
    <Row
      justify="center"
      align="middle"
      style={{
        height: config.RECHART_HEIGHT,
        overflow: "auto",
      }}
    >
      <Col>
        {resultSet.seriesNames().map((s: any) => (
          <Statistic key={s.key} value={resultSet.totalRow()[s.key]} />
        ))}
      </Col>
    </Row>
  ),
  table: ({ resultSet, pivotConfig }: any) => {
    const dataSource = (resultSet?.tablePivot(pivotConfig) || []).map((item: any) => {
      const _item = Object.entries(item).reduce((acc: any, item: any) => {
        const value = item[1] === null ? <Tag>null</Tag> : item[1];
        acc[item[0]] = value;
        return acc;
      }, {});
      return {
        ..._item,
        id: uniqueId(),
      };
    });
    return (
      <div className="table-amanotes w-full h-full">
        <Table
          rowKey={"id"}
          size="small"
          dataSource={dataSource}
          columns={resultSet.tableColumns(pivotConfig)}
          scroll={{ x: "100%" }}
          pagination={false}
        />
      </div>
    );
  },
};
const TypeToMemoChartComponent = Object.keys(TypeToChartComponent)
  .map((key) => ({
    [key]: React.memo(TypeToChartComponent[key]),
  }))
  .reduce((a, b) => ({ ...a, ...b }));

const renderChart =
  (Component: any) =>
  ({ resultSet, isLoading, error, pivotConfig, ...props }: any) => {
    if (!resultSet && isLoading)
      return (
        <div className="flex items-center justify-center w-full h-[200px]">
          <Spin />
        </div>
      );

    if (!resultSet || error) {
      return (
        <div className="flex items-center justify-center w-full h-full">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
      );
    }

    return <>{resultSet && <Component resultSet={resultSet} pivotConfig={pivotConfig} {...props} />}</>;
  };

type IProps = {
  vizState: any;
  renderProps: any;
};

const ChartRenderer = ({ vizState, renderProps }: IProps) => {
  const { mode } = useColorContext();
  const {
    // query,
    chartType,
    ...options
  } = vizState;
  const component = TypeToMemoChartComponent[chartType];
  // const renderProps = useCubeQuery(query);
  return component && renderChart(component)({ ...options, ...renderProps, mode });
};

export const ChartRendererNew = ({ vizState, renderProps }: any) => {
  const { chartType, pivotConfig } = vizState;
  const component = TypeToMemoChartComponent[chartType];
  return component && renderChart(component)({ ...renderProps, pivotConfig });
};

export default ChartRenderer;
