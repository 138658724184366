import { blue } from "@ant-design/colors";
import { CloseOutlined, EditOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { css } from "@emotion/react";
import { paramOpts } from "@src/types/param";
import { Button, Form, FormInstance, Input, InputRef, Select, Tag } from "antd";
import React from "react";

interface Props {
  name: number;
  form: FormInstance<object>;
}

export const EventParamContentTabForm: React.FC<Props> = ({ name, form }) => {
  return (
    <Form.List name={[name, "event_params"]}>
      {(fields_param, { add: addParam, remove: removeParam }, { errors: errorsParams }) => (
        <div className="flex flex-col py-2">
          <Button
            icon={<PlusCircleOutlined />}
            size="small"
            type="primary"
            className="cursor-pointer text-gray-6  text-[12px] w-max mb-1"
            onClick={() =>
              addParam({
                is_new: true,
                param_type: "string",
              })
            }
          >
            Add param
          </Button>
          <div className="flex flex-wrap">
            {fields_param.map(({ key: keyParam, name: nameParam }) => (
              <EventParam key={keyParam} nameParam={nameParam} name={name} remove={removeParam} form={form} />
            ))}
          </div>
          {errorsParams.length > 0 && <div className="text-[red] mb-4">{errorsParams}</div>}
        </div>
      )}
    </Form.List>
  );
};

const EventParam: React.FC<{
  name: number;
  remove: (name: number) => void;
  nameParam: number;
  form: FormInstance<object>;
}> = ({ name, remove, nameParam, form }) => {
  const param_key = Form.useWatch(["analytic_events", name, "event_params", nameParam, "param_key"]);
  const is_new = Form.useWatch(["analytic_events", name, "event_params", nameParam, "is_new"]);
  const [isEditing, setIsEditing] = React.useState(Boolean(is_new));
  React.useEffect(() => {
    setIsEditing(is_new);
  }, [is_new]);

  const editInputRef = React.useRef<InputRef>(null);
  React.useEffect(() => {
    isEditing && editInputRef.current?.focus();
  }, [isEditing]);
  const handleEditInputConfirm = () => {
    param_key && setIsEditing(false);
    form.validateFields([["analytic_events", name, "event_params", nameParam, "param_key"]]);
  };
  return (
    <Form.Item
      name={[nameParam, "param_key"]}
      rules={[{ required: true, message: "Please enter param key" }]}
      className="mb-1 mx-1 text-[12px]"
    >
      <Tag color="blue" className="flex mx-0 items-center px-2 shadow-md justify-center duration-100 transition-all">
        {isEditing ? (
          <Input
            ref={editInputRef}
            key={nameParam}
            value={param_key}
            size="small"
            onBlur={handleEditInputConfirm}
            onPressEnter={handleEditInputConfirm}
            className="mr-2 text-[12px]"
          />
        ) : (
          <span className="mr-4">{param_key}</span>
        )}
        <Form.Item name={[nameParam, "param_type"]} className="mb-0 mr-5 text-[12px]">
          <Select
            options={paramOpts}
            popupMatchSelectWidth={100}
            size="small"
            className="min-w-[80px]"
            css={css`
              .ant-select-selection-item {
                font-size: 12px;
              }
            `}
          />
        </Form.Item>
        <div
          className="flex p-1 space-x-2 rounded px-2 ml-auto"
          css={css`
            background-color: ${blue[1]};
          `}
        >
          <EditOutlined
            onClick={() => {
              setIsEditing(true);
            }}
          />
          <CloseOutlined
            className="text-red-400"
            onClick={(e) => {
              setIsEditing(false);
              remove(nameParam);
              e.preventDefault();
              e.stopPropagation();
            }}
          />
        </div>
      </Tag>
    </Form.Item>
  );
};
