import React from "react";
import { Select } from "antd";

// types
import { IFilterQuery, IMemberFilter, IMembersTreeBase } from "@src/types/query-builder";

// helpers
import { mapMemberToTreeNode } from "@src/helpers/tree-select-member";

// components
import MemberGroupBase from "./member-group-base";
import FilterInput from "../filter-input";
import MemberTagBase from "./member-tag-base";

type IProps = IMembersTreeBase & {
  members: IMemberFilter[];
};

function MemberGroupFilter({
  title,
  description = "Select item to display",
  availableMembers,
  members,
  updateMethods,
  updateQuery,
  query,
  isFetchingMeta,
}: IProps) {
  const cubOptions = React.useMemo(() => mapMemberToTreeNode(availableMembers), [availableMembers]);
  const getDefaultCheckedKeys = React.useMemo(
    () => (query?.filters ? query.filters.map((m: any) => m.member) : []),
    [query.filters],
  );

  function onRemoveMember(member: IMemberFilter) {
    updateMethods.remove(member);
  }

  function addMembers(selectedMembers: React.Key[]) {
    const filters: IFilterQuery[] = query?.filters || [];
    updateQuery({
      filters: selectedMembers.map((member) => {
        const filterItem = filters.find((m) => m.member === member);
        return {
          member,
          operator: filterItem ? filterItem.operator : "equals",
          values: filterItem ? filterItem.values : undefined,
        };
      }),
    });
  }

  return (
    <MemberGroupBase
      title={title}
      dataSource={cubOptions}
      query={query}
      isFetchingMeta={isFetchingMeta}
      getDefaultCheckedKeys={getDefaultCheckedKeys}
      onOk={(selectedMembers: React.Key[]) => {
        addMembers(selectedMembers);
      }}
    >
      <div className="flex flex-wrap">
        {members.length > 0 ? (
          <>
            {members.map((member) => {
              return (
                <div key={member.dimension.name} className="flex items-center flex-wrap">
                  <div className="mb-2">
                    <MemberTagBase title={member.dimension.title} member={member} onRemoveMember={onRemoveMember} />
                  </div>

                  <div className="mx-2 mb-2">
                    <Select
                      defaultValue="equals"
                      style={{ width: 180 }}
                      onChange={(operator) => updateMethods.update(member, { ...member, operator })}
                    >
                      {member.operators.map((operator) => (
                        <Select.Option key={operator.name} value={operator.name}>
                          {operator.title}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                  <div className=" mb-2">
                    <FilterInput member={member} key="filterInput" updateMethods={updateMethods} />
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <>{description}</>
        )}
      </div>
    </MemberGroupBase>
  );
}

export default MemberGroupFilter;
