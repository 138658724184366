import React from "react";
import { List } from "@refinedev/antd";
import { FilterExploration } from "./component/FilterExploration";

export const ABTestExplore: React.FC = () => {
  return (
    <>
      <List
        title={<span>A/B Test Exploration</span>}
        headerProps={{
          extra: false,
        }}
      >
        <FilterExploration />
      </List>
    </>
  );
};
