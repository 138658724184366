import React from "react";
import { Menu } from "antd";
// components
import ButtonDropdown from "../button-dropdown";

// configs
import { GRANULARITIES } from "@src/config/query-builder";

// types
import { IGranularity, IMemberDimensions, IMemberTime } from "@src/types/query-builder";
import MemberTagBase from "./member-tag-base";
import { getModeOfLocation } from "@src/util/fb-exploration/get-mode-of-location";
type IProps = {
  members: IMemberDimensions[];
  timeDimensions: IMemberTime[];
  updateTimeMethods: any;
  onRemoveMembers: (member: IMemberDimensions) => void;
};

function MemberTagDimension({ members, timeDimensions, updateTimeMethods, onRemoveMembers }: IProps) {
  const { isViewMode } = getModeOfLocation();
  const granularityMenu = (onClick: (granularity: IGranularity) => void) => (
    <Menu>
      {GRANULARITIES.map((m) => (
        <Menu.Item key={m.title} onClick={() => onClick(m)}>
          {m.title}
        </Menu.Item>
      ))}
    </Menu>
  );

  function onUpdateGranularity(member: IMemberDimensions, granularity: IGranularity) {
    const dimensionItem = timeDimensions.find((m) => m.dimension.name === member.name);
    const payload: any = {
      dimension: {
        ...member,
      },
      granularity: granularity.name,
      index: dimensionItem ? dimensionItem.index : timeDimensions.length + 1,
    };
    if (dimensionItem) {
      payload.dateRange = dimensionItem.dateRange;
    }
    updateTimeMethods.update(payload, { ...payload, granularity: granularity.name });
  }

  return (
    <div className="flex flex-wrap">
      {members.map((member) => {
        const titleGranularity = timeDimensions.find((m) => m.dimension.name === member.name)?.granularity;
        return (
          <MemberTagBase member={member} key={member.title} onRemoveMember={onRemoveMembers}>
            <span className=" inline-block mr-2 ml-2">by</span>
            <ButtonDropdown
              disabled={!!isViewMode}
              size="small"
              overlay={granularityMenu((granularity: IGranularity) => {
                onUpdateGranularity(member, granularity);
              })}
              className="capitalize"
            >
              {titleGranularity || GRANULARITIES[0].title}
            </ButtonDropdown>
          </MemberTagBase>
        );
      })}
    </div>
  );
}

export default MemberTagDimension;
