import React from "react";
import { Button, Modal } from "antd";
import { useCreate } from "@refinedev/core";

// adapter
import { MultiStepAdapter } from "@src/adapter";

// types
import { IExplorerNode } from "@src/types/explorer";

// components
import CodeEditor from "@src/components/editor/code-editor";

// contexts
import { useMultiStepQuery } from "@src/contexts/multi-step-query-context";

const multiStepAdapter = new MultiStepAdapter();

function MonancoPanel() {
  const { updateMethodsNode, nodeBaseItem, messageApi } = useMultiStepQuery();
  const nodeBaseConfig = updateMethodsNode.getNodeBaseConfig();
  // states
  const [openModalEditor, setOpenModalEditor] = React.useState(false);
  const [code, setCode] = React.useState<any>(nodeBaseConfig);
  // refine hook
  const { mutateAsync: mutateImportJson, isLoading: isLoadingImportJson } = useCreate();

  function closeModalEditor() {
    setOpenModalEditor(false);
    setCode("");
  }

  function handleEditorChange(value: string | undefined) {
    setCode(value || "");
  }

  function importJson() {
    const bodyData = {
      configs: Object.values(JSON.parse(code)),
      target_aliases: Object.keys(JSON.parse(code)),
    };
    mutateImportJson({
      resource: `multistep_query/dryrun`,
      values: bodyData,
      successNotification: (res) => {
        // handle show error message
        if (
          (res?.data?.results.length === 0 && Object.keys(res?.data.errors || {}).length > 0) ||
          res?.data?.results[0].ex
        ) {
          return {
            type: "error",
            message: `Import Failed!`,
          };
        }
        const results: IExplorerNode[] = res?.data?.results || [];
        const nodeItem = results.filter((item) => !nodeBaseItem?.hasOwnProperty(item.alias));
        if (nodeItem.length === 0)
          return {
            type: "error",
            message: `Node empty!`,
          };

        // add node in reat flow
        const { nodeBaseItem: dataNodeBaseItem, nodeGeneratedItem } = multiStepAdapter.getResultDryrun({
          node: nodeItem[0],
          name: nodeItem[0].name,
        });
        updateMethodsNode.add(dataNodeBaseItem, nodeGeneratedItem);

        closeModalEditor();
        return {
          type: "success",
          message: "Imported successfully!",
        };
      },
      errorNotification: () => {
        return {
          type: "error",
          message: `Created Failed!`,
        };
      },
    });
  }

  return (
    <>
      <Button
        type="dashed"
        className="border-gray-300 border-r-[1px] px-[8px]"
        onClick={() => setOpenModalEditor(true)}
      >
        JSON editor
      </Button>

      <Modal
        key={Number(openModalEditor)}
        title="JSON Editor"
        open={openModalEditor}
        width={850}
        className="modal-amanotes"
        okText="Import"
        onOk={importJson}
        okButtonProps={{ loading: isLoadingImportJson }}
        onCancel={closeModalEditor}
      >
        <CodeEditor
          handleEditorChange={handleEditorChange}
          code={JSON.stringify(nodeBaseConfig, null, 2)}
          onValidate={(markers) => {
            if (markers.length > 0) {
              messageApi.error("Invalid JSON format");
            }
          }}
        />
      </Modal>
    </>
  );
}

export default MonancoPanel;
