import { IMemberFilter } from "@src/types/query-builder";
import { Select, Input } from "antd";

type IFilterInputs = {
  [key: string]: ({ values, onChange }: any) => JSX.Element;
};

const FilterInputs: IFilterInputs = {
  string: ({ values, onChange }) => (
    <Select key="input" style={{ width: 200 }} mode="tags" onChange={onChange} value={values} />
  ),
  number: ({ values, onChange }) => (
    <Input
      key="input"
      style={{ width: 200 }}
      onChange={(e) => onChange([e.target.value])}
      value={(values && values[0]) || ""}
    />
  ),
};

type IProps = {
  member: IMemberFilter;
  updateMethods: any;
};

// set, notSet: not show value field
const FilterInput = ({ member, updateMethods }: IProps) => {
  const isNotValueField = ["set", "notSet"].includes(member.operator);
  const Filter = FilterInputs[member.dimension.type] || FilterInputs.string;

  if (isNotValueField) return null;

  return (
    <Filter
      key="filter"
      values={member.values}
      onChange={(values: any) => updateMethods.update(member, { ...member, values })}
    />
  );
};

export default FilterInput;
