import React, { useRef } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Tooltip } from "antd";
import { Handle, Position } from "reactflow";

// types
import { INodeItem } from "@src/types/explorer";

// contexts
import { useMultiStepQuery } from "@src/contexts/multi-step-query-context";
import { useWarehouseContext } from "@src/contexts/warehouse-context";

// configs
import { DEFAULT_QUERY_BUILDER, ADAPTER_QUERY_BUILDER } from "@src/config/query-builder";

function getAdapterBadge(adapter_name?: string) {
  switch (adapter_name) {
    case ADAPTER_QUERY_BUILDER.cube.value:
      return (
        <span className="inline-flex items-center rounded-md bg-pink-50 px-2 py-1 text-[8px] font-medium text-pink-700 ring-1 ring-inset ring-pink-700/10">
          {adapter_name}
        </span>
      );
    case ADAPTER_QUERY_BUILDER["dbt-like"].value:
      return (
        <span className="inline-flex items-center rounded-md bg-pink-50 px-2 py-1 text-[8px] font-medium text-pink-700 ring-1 ring-inset ring-pink-700/10">
          {adapter_name}
        </span>
      );
    default:
      return (
        <span className="inline-flex items-center rounded-md bg-pink-50 px-2 py-1 text-[8px] font-medium text-pink-700 ring-1 ring-inset ring-pink-700/10">
          Unknown
        </span>
      );
  }
}

function getValidationStatus(status?: string, ex?: any) {
  const bgColors = {
    success: "bg-green-200",
    warn: "bg-yellow-200",
    error: "bg-red-200",
  };

  const textColors = {
    success: "text-green-600",
    warn: "text-yellow-600",
    error: "text-red-600",
  };

  function getColor() {
    if ((!status && ex) || status !== "success") return "error";
    else if (status === "success") return "success";
    else return "warn";
  }

  return (
    // <div className={`row-span-1 ${bgColors[getColor()]} grid grid-flow-col grid-cols-4 p-1`}>
    <div className={`${bgColors[getColor()]} grid grid-flow-col grid-cols-1 p-1`}>
      <div className="justify-self-start self-center">
        <span className={`${textColors[getColor()]} text-[8px] font-mono`}>Validation: {status}</span>
      </div>
    </div>
  );
}

function ExplorerNode(node: INodeItem) {
  const ref = useRef(null);

  const { updateQuery } = useWarehouseContext();
  const { nodeBaseItem, updateMethodsNode, updateMethodsQuerySidebar, nodeBaseGeneratedItem, layoutedEdges } =
    useMultiStepQuery();

  const currBaseNode = nodeBaseItem?.[node.id];
  const currNodeMeta = nodeBaseGeneratedItem?.[node.id];
  const title = currBaseNode?.name || "";
  const stepAlias = currBaseNode?.alias || "";

  const { isTarget, isSource } = React.useMemo(() => {
    const isTarget = layoutedEdges.some((e) => e.target === node.id);
    const isSource = layoutedEdges.some((e) => e.source === node.id);
    return { isTarget, isSource };
  }, [layoutedEdges, node]);

  return (
    <div ref={ref}>
      <div className="react-flow__node-default overflow-hidden rounded-lg">
        {isTarget ? (
          <Handle type="target" position={node?.targetPosition == "top" ? Position.Top : Position.Left} />
        ) : null}
        {isSource ? (
          <Handle type="source" position={node?.sourcePosition == "bottom" ? Position.Bottom : Position.Right} />
        ) : null}

        <div className="absolute -top-1 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
            {stepAlias}
          </span>
          {/* {getAdapterBadge(currBaseNode?.adapter_name)} */}
        </div>
        <div className="absolute bottom-0 right-0 transform translate-y-5">
          {getAdapterBadge(currBaseNode?.adapter_name)}
        </div>
        <div className="grid grid-flow-row grid-rows-3">
          <div className="row-span-1 grid grid-flow-col grid-cols-5 bg-slate-300 p-1">
            <div className="justify-self-start self-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 0 1-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0 1 12 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5"
                />
              </svg>
            </div>
            {/* <p className="text-[10px] m-0 justify-self-start self-center">{node.data.label}</p> */}
            <p className="text-[10px] m-0 justify-self-start self-center">{title}</p>

            <div className="col-span-3 justify-self-end self-center flex items-center">
              <Tooltip title="Delete">
                <Popconfirm
                  title="Delete the node"
                  description="Are you sure to delete this node?"
                  onConfirm={() => {
                    const isDeleted = updateMethodsNode.remove(node);
                    if (isDeleted) {
                      updateQuery(DEFAULT_QUERY_BUILDER);
                      updateMethodsQuerySidebar.open(true, "edit");
                    }
                  }}
                  showCancel={false}
                  okText="Yes"
                >
                  <Button
                    danger
                    disabled={isSource}
                    type="text"
                    size="small"
                    className="shrink-0 m-0 p-0 flex items-center justify-center w-[16px] h-[16px]"
                    icon={<DeleteOutlined style={{ fontSize: 10 }} />}
                  />
                </Popconfirm>
              </Tooltip>
            </div>
          </div>
          <div className="row-span-1 justify-self-start p-1">
            <p className="text-[8px]">No description</p>
          </div>

          <div className="row-span-1 grid grid-flow-col">
            {getValidationStatus(currNodeMeta?.status, currNodeMeta?.ex)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExplorerNode;
