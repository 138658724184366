import { blue, magenta } from "@ant-design/colors";
import { CloseOutlined, EditOutlined } from "@ant-design/icons";
import { css } from "@emotion/react";
import { Form, FormInstance, Input, InputRef, Tag } from "antd";
import React from "react";

interface Props {
  name: number;
  remove: (name: number) => void;
  form: FormInstance<object>;
}

export const AnalyticEventsTabLabel: React.FC<Props> = ({ name, remove, form }) => {
  const event_name = Form.useWatch(["analytic_events", name, "event_name"]);
  const is_new = Form.useWatch(["analytic_events", name, "is_new"]);
  const [isEditing, setIsEditing] = React.useState(Boolean(is_new));
  React.useEffect(() => {
    setIsEditing(is_new);
  }, [is_new]);

  const editInputRef = React.useRef<InputRef>(null);
  React.useEffect(() => {
    isEditing && editInputRef.current?.focus();
  }, [isEditing]);
  const handleEditInputConfirm = () => {
    event_name && setIsEditing(false);
    form.validateFields([["analytic_events", name, "event_name"]]);
  };
  return (
    <Form.Item
      className="mb-0 w-full relative"
      name={[name, "event_name"]}
      rules={[{ required: true, message: "Please enter event name" }]}
    >
      <Tag
        color={"magenta"}
        className="flex justify-between items-center w-[calc(100%+100px)] p-3 px-2 shadow-md static"
      >
        {isEditing ? (
          <Input
            ref={editInputRef}
            key={name}
            size="small"
            value={event_name}
            onBlur={handleEditInputConfirm}
            onPressEnter={handleEditInputConfirm}
            className="w-[150px] text-[12px]"
            css={css`
              border-color: ${magenta[2]} !important;
            `}
          />
        ) : (
          <span>{event_name}</span>
        )}
        <div
          className="flex p-1 rounded px-2 absolute right-0"
          css={css`
            background-color: ${magenta[2]};
          `}
        >
          <EditOutlined onClick={() => setIsEditing(true)} />
          <CloseOutlined className="text-red-400" onClick={() => remove(name)} />
        </div>
      </Tag>
    </Form.Item>
  );
};
