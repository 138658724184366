import { CopyOutlined } from "@ant-design/icons";
import { BaseKey } from "@refinedev/core";
import { useDuplicateFirebaseExplore } from "@src/hooks/fb-explore/use-duplicate-firebase-explore";
import { Button, Popconfirm } from "antd";

type DuplicateButtonProps = {
  id?: string | BaseKey;
};
export const DuplicateButton: React.FC<DuplicateButtonProps> = ({ id }) => {
  const { handleDuplicate } = useDuplicateFirebaseExplore({
    id: id as any,
    replace: false,
  });

  const onDuplicate = async () => {
    handleDuplicate({});
  };
  return (
    <Popconfirm title="Are you sure duplicate this exploration?" onConfirm={() => onDuplicate()}>
      <Button icon={<CopyOutlined />} size="small" />
    </Popconfirm>
  );
};
