import { Create, ListButton, SaveButton, useForm } from "@refinedev/antd";
import { useExploreContext } from "@src/contexts/explore-context";
import React from "react";
import { useCreate, useNavigation } from "@refinedev/core";
import { Card, Form } from "antd";
import { GeneralInfoWrapper } from "./form/general-info-wrapper";
import ExploreQueryNew from "@src/pages/dashboard/components/explore-builder-new";
import { ChartResultProvider } from "./form/chart-result-provider";

export const FirebaseExploreCreate: React.FC = () => {
  const { push, replace } = useNavigation();
  const { formProps } = useForm({
    resource: "explorations",
    dataProviderName: "nestjsx",
    redirect: "list",
  });
  const { query, chartType, pivotConfig, product } = useExploreContext();

  const { mutateAsync: createNewFirebaseExplore } = useCreate();

  const handleFinish = async (e: any) => {
    formProps.form?.validateFields();
    const payload = {
      title: e.name,
      description: e?.description,
      product_code: e.product_code,
      is_public: true,
      chart_type: chartType,
      pivot_config: pivotConfig,
      // ...query, // include dimensions/measures/segments/limit/orders
      filter: query?.filters,
      dimensions: query?.dimensions,
      measures: query?.measures,
      segments: query?.segments,
      order: query?.order,
      limit: query?.limit,
      time_dimensions: query?.timeDimensions,
    };

    createNewFirebaseExplore({
      resource: `exploration/fb-exploration`,
      values: payload,
      successNotification: () => {
        return {
          type: "success",
          message: "Exploration created  successfully",
        };
      },
      errorNotification: () => {
        return {
          type: "error",
          message: "Exploration created  error",
        };
      },
    }).then((res) => {
      const newId = res?.data?.id;
      replace(`/explorations/firebase/edit/${newId}`, {});
    });
  };

  return (
    <Create
      title="Create exploration"
      headerButtons={<ListButton onClick={() => push("/")}>Explorations</ListButton>}
      contentProps={{
        className: "bg-transparent shadow-none card-custom",
      }}
      footerButtonProps={{
        hidden: true,
      }}
      wrapperProps={{
        className: "base-custom-container",
      }}
    >
      <Form preserve layout="vertical" {...formProps} onFinish={handleFinish}>
        <Card size="small" title={"General Information"} className="mb-2">
          <div className="flex mb-4 ">
            <div className="w-3/4">
              <GeneralInfoWrapper form={formProps.form} mode="create" />
            </div>
            <div className="w-1/4">
              <SaveButton htmlType="submit" className=" absolute bottom-10 right-2">
                Save
              </SaveButton>
            </div>
          </div>
        </Card>

        {product && (
          <div className="flex w-full space-x-2 h-[calc(100vh-80px)] relative">
            <Card
              size="small"
              title={"Query Builder"}
              className="query-builder w-1/3 "
              classNames={{
                body: "h-[calc(100%-50px)] overflow-y-auto",
              }}
            >
              <ExploreQueryNew />
            </Card>
            <div className="w-2/3  h-full flex flex-col space-y-2">
              <ChartResultProvider mode="create" form={formProps.form} />
            </div>
          </div>
        )}
      </Form>
    </Create>
  );
};
