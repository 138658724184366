import { useExploreContext } from "@src/contexts/explore-context";
import { ChartResult } from "./chart-result";
import { IChart } from "@src/types/chart";
import { FormInstance } from "antd";

export const ChartResultCreate: React.FC<{ form?: FormInstance<any> }> = ({ form }) => {
  const { chartType, validatedQuery, pivotConfig } = useExploreContext();

  const layout: IChart = {
    id: Math.random(),
    name: "layout",
    vizState: {
      query: validatedQuery,
      pivotConfig,
      chartType,
    },
  };
  return <ChartResult mode="create" layout={layout} pivotConfig={pivotConfig} form={form} />;
};
