import React from "react";
import { Result } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { useLogout } from "@refinedev/core";

import { PAGE_URL } from "@src/config";

const Error401: React.FC = () => {
  const { mutate: logout } = useLogout();
  const [count, setCount] = React.useState(5);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setCount((prev) => prev - 1);
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  React.useEffect(() => {
    if (count === 0) {
      logout();
      window.location.href = PAGE_URL.LOGIN;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  return (
    <div className="flex justify-center items-center h-full">
      <Result
        icon={<LogoutOutlined />}
        title="Sorry, your session are expired"
        subTitle={
          <>
            System will redirect you to login page... after <b>{count}</b> seconds
          </>
        }
      />
    </div>
  );
};

export default Error401;
