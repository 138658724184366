import React from "react";
import { createRoot } from "react-dom/client";

import App from "./App";

// styles
import "@refinedev/antd/dist/reset.css";
import "./styles/antd.scss";
import "./styles/variables.css";
import "./styles/grid-layout.css";
import "./styles/rechart.css";
import "./styles/index.css";
import "./styles/query-builder.scss";

// components
import ErrorBoundary from "./components/error-boundary/error-boundary";
import { LoadingFullScreen } from "./components/loading/loading-full-screen";
import { Auth0Provider } from "@auth0/auth0-react";
import { config } from "./config";

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

root.render(
  <Auth0Provider
    domain={config.AUTH0_DOMAIN}
    clientId={config.AUTH0_CLIENT_ID}
    authorizationParams={{
      redirect_uri: window.location.origin,
    }}
  >
    <React.Suspense fallback={<LoadingFullScreen />}>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </React.Suspense>
  </Auth0Provider>,
);
