import React from "react";
import { pickNotDeprecated, useActiveAuthProvider, useGetIdentity, useLogout } from "@refinedev/core";
import { Layout as AntdLayout, Avatar, Space, Dropdown, MenuProps } from "antd";
import type { RefineThemedLayoutV2HeaderProps } from "@refinedev/antd";

export const ThemedHeaderV2: React.FC<RefineThemedLayoutV2HeaderProps> = ({ isSticky, sticky }) => {
  const { mutate: logout } = useLogout();
  const authProvider = useActiveAuthProvider();
  const { data: user } = useGetIdentity({
    v3LegacyAuthProviderCompatible: Boolean(authProvider?.isLegacy),
  });

  const shouldRenderHeader = user && (user.name || user.avatar);

  if (!shouldRenderHeader) {
    return null;
  }

  const headerStyles: React.CSSProperties = {
    backgroundColor: "white",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0px 24px",
    height: "64px",
  };

  if (pickNotDeprecated(sticky, isSticky)) {
    headerStyles.position = "sticky";
    headerStyles.top = 0;
    headerStyles.zIndex = 99;
  }

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: <div onClick={() => logout()}>Logout</div>,
    },
  ];

  return (
    <AntdLayout.Header style={headerStyles}>
      <Space>
        <Space size="middle">
          <Dropdown className="ml-auto max-w-max" menu={{ items }} placement="bottomRight">
            <div className="flex px-0 py-2 text-sm text-black transition-all ease-nav-brand flex-row items-center cursor-pointer  ml-auto">
              <div className="relative mr-[10px]">{user?.avatar && <Avatar src={user?.avatar} alt={user?.name} />}</div>
              <span className="sm:inline pl-2 text-ellipsis">{user.email || "Anonymous"}</span>
            </div>
          </Dropdown>
        </Space>
      </Space>
    </AntdLayout.Header>
  );
};
