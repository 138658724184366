import React from "react";
import { Button, Tag, Tree, TreeDataNode, TreeProps } from "antd";
import { DataNode } from "rc-tree/lib/interface";
import { ICubeNode } from "@src/types/query-builder";
import { CloseOutlined } from "@ant-design/icons";
import { filteredSelectedMembers } from "@src/helpers/tree-select-member";

const renderTitle = ({ dataSource, searchValue }: any) => {
  const loop = (data: ICubeNode[], parentKey: string[] | null): DataNode[] =>
    data.map((item): any => {
      const strTitle = JSON.parse(JSON.stringify(item.title as string));
      const index = strTitle.toLowerCase().indexOf(searchValue.toLowerCase());
      const beforeStr = item.title.substring(0, index);
      const afterStr = item.title.slice(index + searchValue.length);

      let disabled = false;
      if (searchValue !== undefined && searchValue.length > 0 && index === -1) {
        disabled = true;
      }
      const title =
        index > -1 ? (
          <span>
            {beforeStr}
            <span className="site-tree-search-value">{item.title.substring(index, index + searchValue.length)}</span>
            {afterStr}
          </span>
        ) : (
          <span>{strTitle}</span>
        );
      if (item.children) {
        const children = loop(item.children, parentKey ? [...parentKey, item.key] : [item.key]);
        return {
          title,
          parentKey,
          key: item.key,
          // disabled: children.filter((child) => !child.disabled).length === 0,
          // selectable: !disabled,
          // expanded: true,
          children: children,
          style: {
            display: children.filter((child) => !child.disabled).length === 0 ? "None" : null,
          },
        };
      }

      return {
        title,
        public: item.public,
        // selectable: isLeaf ? !disabled : disabled,
        parentKey,
        // disabled: disabled,
        key: item.key,
        style: {
          display: disabled ? "None" : null,
        },
      };
    });

  return loop(dataSource, null);
};

type IProps = TreeProps & {
  dataSource: TreeDataNode[];
  searchValue: string;
  isSelectedMode?: boolean;
  setCheckedKeys?: React.Dispatch<React.SetStateAction<React.Key[]>>;
  setSelectedMembers?: React.Dispatch<React.SetStateAction<TreeDataNode[]>>;
};

function TreeSelectBase({
  dataSource,
  searchValue,
  isSelectedMode,
  setCheckedKeys,
  setSelectedMembers,
  ...restTreeProps
}: IProps) {
  const treeData = React.useMemo(() => {
    return renderTitle({
      dataSource,
      searchValue,
    });
  }, [dataSource, searchValue]);

  return (
    <Tree
      showLine
      treeData={treeData}
      titleRender={(node: any) => {
        return (
          <div className="flex items-center w-full">
            {node.title}
            {!node.children && !node?.public && (
              <div className="shrink ml-2">
                <Tag color="gold">Test Mode</Tag>
              </div>
            )}
            {isSelectedMode && !node.children && (
              <Button
                className="text-red-500 ml-2"
                size="small"
                type="text"
                onClick={() => {
                  setCheckedKeys?.((prev) => {
                    const parentKey = node.parentKey ?? [];
                    const keys = prev.filter((item) => ![...parentKey, node.key].includes(item));
                    const membersFiltered = filteredSelectedMembers(dataSource, keys);
                    setSelectedMembers?.(membersFiltered);
                    return keys;
                  });
                }}
              >
                <CloseOutlined />
              </Button>
            )}
          </div>
        );
      }}
      {...restTreeProps}
    />
  );
}

export default TreeSelectBase;
