import { useForm } from "@refinedev/antd";
import { Button, Card, Form } from "antd";
import { useCustom } from "@refinedev/core";
import { config } from "@src/config";
import { CubeProvider } from "@cubejs-client/react";
import cubejs from "@cubejs-client/core";

import {
  AbaIdFilter,
  InstallDateFilter,
  MaxDateDiffFilter,
  ProductFilter,
  SubmitButton,
  TimeRangeFilter,
  VariantFilter,
} from "./form";

export const FilterExploration: React.FC<any> = () => {
  const { form } = useForm();
  const productCodeWatch = Form.useWatch("product_code", form);

  const { data } = useCustom({
    url: `${config.BACKEND_URL}/product/${productCodeWatch}/generate-cube-token`,
    method: "get",
    queryOptions: {
      retry: 0,
      enabled: !!productCodeWatch,
      onSuccess: (data) => {
        const token = data?.data?.jwt_token;
        if (token) {
          form.setFieldValue("product_token", token);
        }
      },
    },
    errorNotification: () => {
      return {
        type: "error",
        message: "There was an error fetching data",
        description: "Error",
      };
    },
  });

  const CUBE_TOKEN = data?.data?.jwt_token;
  const cubejsApi = cubejs(CUBE_TOKEN, { apiUrl: config.CUBE_URL });

  const handleFinish = (values: any) => {
    console.log("values ", values);
  };
  return (
    <Card>
      <CubeProvider cubeApi={cubejsApi}>
        <Form
          name="filter_exploration"
          form={form}
          layout="vertical"
          onFinish={handleFinish}
          initialValues={{
            time_range: config.DEFAULT_TIME_RANGE,
          }}
        >
          <div className="flex flex-wrap">
            <ProductFilter form={form} />
            <TimeRangeFilter />
            <AbaIdFilter form={form} />
            <VariantFilter form={form} />
            <InstallDateFilter />
            <MaxDateDiffFilter />
            <SubmitButton form={form} />
          </div>
        </Form>
      </CubeProvider>
    </Card>
  );
};
