import { AutoCompleteWrapper } from "@src/components/autocomplete/autocomplete-wrapper";
import TimeGroupITem from "@src/components/query-builder/time-group-item";
import { config } from "@src/config";
import { useExploreContext } from "@src/contexts/explore-context";
import { playgroundActionUpdateMethods } from "@src/helpers/update-method-query";
import { IMemberTime } from "@src/types/query-builder";
import { IProduct } from "@src/types/user";
import { getModeOfLocation } from "@src/util/fb-exploration/get-mode-of-location";
import { Form, FormInstance, Input } from "antd";
import React from "react";

export const GeneralInfoWrapper: React.FC<{ mode: "create" | "edit"; form?: FormInstance<object> }> = ({
  form,
  mode,
}) => {
  const { isViewMode } = getModeOfLocation();
  const { setProduct, product, isFetchingMeta, timeDimensions, updateTimeDimensions } = useExploreContext();
  const [isEditingAutoComplete, setIsEditingAutoComplete] = React.useState(false);
  const member: IMemberTime | undefined = timeDimensions.find(
    (member: IMemberTime) => member.dimension.name === config.FIREBASE_EVENT_TIMESTAMP,
  );
  const updateMethods = playgroundActionUpdateMethods(updateTimeDimensions);

  const onSetProduct = (value: IProduct) => {
    setProduct(value);
    if (mode === "create") return;
    const product_code = value.productCode;
    form?.setFieldValue("product_code", product_code);
    form?.validateFields();
  };

  React.useEffect(() => {
    if (mode === "edit") return;
    if (isFetchingMeta || !product) return;
    form?.setFieldValue("product_code", product.productCode);
    form?.validateFields(["product_code"]);
  }, [product, form, isFetchingMeta]);

  return (
    <div>
      <div className=" w-full flex items-center space-x-2 mb-4">
        <Form.Item
          label={<span className="font-bold">Product</span>}
          className="w-2/5 mb-0"
          rules={[
            {
              required: true,
              message: "Please select product",
            },
          ]}
          name={"product_code"}
        >
          <AutoCompleteWrapper
            setProduct={onSetProduct}
            product={product}
            isEditing={isViewMode ? false : isEditingAutoComplete}
            setIsEditing={setIsEditingAutoComplete}
          />
        </Form.Item>
        <Form.Item
          name="name"
          label={<span className="font-bold">Title</span>}
          className="w-1/5 mb-0 pr-10"
          rules={[
            {
              required: true,
              message: "Please enter title",
            },
          ]}
        >
          <Input disabled={!!isViewMode} type="text" name="name" placeholder="Title" />
        </Form.Item>
        <Form.Item label={<span className="font-bold">Time range</span>} className="w-2/5 mb-0">
          <div className="flex items-center space-x-2 ">
            <TimeGroupITem member={member} updateMethods={updateMethods} />
          </div>
        </Form.Item>
      </div>
      <Form.Item name="description" label={<span className="font-bold">Description</span>} className="mb-0 ">
        <Input disabled={!!isViewMode} placeholder="Description" style={{ width: 600 }} />
      </Form.Item>
    </div>
  );
};
