import { Typography } from "antd";
import { Droppable } from "react-beautiful-dnd";

// components
import DropItem from "./drop-item";

// types
import { IPivotConfig } from "@src/types/query-builder";

type IProps = {
  pivotConfig: IPivotConfig;
  axis: string;
};

export default function DroppableArea({ pivotConfig, axis }: IProps) {
  return (
    <>
      <Typography.Text
        strong
        className="flex justify-center px-2 py-2 bg-[rgba(243,243,251,0.5)] dark:bg-[#202020] border-b border-[rgba(246,246,248,1)]"
      >
        {axis}
      </Typography.Text>

      <div
        data-testid={`pivot-popover-${axis}`}
        style={{
          padding: "8px",
        }}
      >
        <Droppable droppableId={axis}>
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{
                display: "grid",
                gridTemplateColumns: "minmax(0, 1fr)",
                gap: "8px",
                height: "100%",
                minHeight: "32px",
              }}
            >
              {(pivotConfig as any)[axis].map((id: string, index: number) => (
                <DropItem key={id} id={id} index={index} />
              ))}

              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </>
  );
}
