import { useGetIdentity, useNavigation } from "@refinedev/core";
import { LoadingFullScreen } from "@src/components/loading/loading-full-screen";
import { whitelist } from "@src/mocks/email-whitelist";
import { Button, Result } from "antd";
import { PropsWithChildren } from "react";

export const AdminAuth: React.FC<PropsWithChildren> = ({ children }) => {
  const { push } = useNavigation();
  const { data, isLoading } = useGetIdentity<any>();
  console.log({ isLoading });
  if (isLoading) {
    return <LoadingFullScreen />;
  }
  const isValidUser = whitelist.includes(data?.email);
  if (!isValidUser) {
    return (
      <Result
        status="403"
        title="403"
        subTitle="Sorry, you are not authorized to access this page."
        extra={
          <Button
            type="primary"
            onClick={() => {
              push("/");
            }}
          >
            Back Home
          </Button>
        }
      />
    );
  }
  return <div>{children}</div>;
};
