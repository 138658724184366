import React from "react";
import { Row, Col, Divider, Checkbox, Typography } from "antd";

// types
import { IChart } from "@src/types/chart";

// types
import { IPivotConfig } from "@src/types/query-builder";
import { DragOutlined } from "@ant-design/icons";

type IProps = {
  layout: IChart;
  setPivotConfig?: React.Dispatch<any> | undefined;
};

export const AxesPivotVisualization = React.forwardRef(({ layout, setPivotConfig }: IProps, ref: any) => {
  // refs
  const pivotConfig: IPivotConfig = layout.vizState.pivotConfig;
  // state
  const [pivotOver, setPivotOver] = React.useState<IPivotConfig | null>(null);

  // refs

  React.useEffect(() => {
    setPivotOver(pivotConfig);
  }, [pivotConfig]);

  React.useEffect(() => {
    if (!pivotOver) return;
    setPivotConfig?.(pivotOver);
  }, [JSON.stringify(pivotOver)]);

  const renderPivotItem = ({ pivotOver, axis }: { pivotOver: IPivotConfig | null; axis: "x" | "y" }) => {
    return (
      <div>
        <Typography.Text
          strong
          className="flex justify-center px-2 py-2 bg-[rgba(243,243,251,0.5)] dark:bg-[#202020] border-b border-[rgba(246,246,248,1)]"
        >
          {axis}
        </Typography.Text>
        <div
          className="cursor-grab h-full min-h-8 my-4"
          style={{
            display: "grid",
            gridTemplateColumns: "minmax(0, 1fr)",
            gap: "8px",
          }}
        >
          {pivotOver?.[axis]?.map((id: string) => {
            return (
              <div
                style={{
                  border: `1px dashed rgba(213,213,226,1)`,
                  borderRadius: 4,
                  lineHeight: "22px",
                }}
                className="mr-2 px-3 py-[5px] bg-gray-100"
                key={id}
              >
                <Typography.Text ellipsis style={{ maxWidth: "100%" }}>
                  <DragOutlined style={{ marginRight: 8 }} />
                  {id}
                </Typography.Text>
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  return (
    <>
      {pivotOver && (
        <>
          <Row>
            <Col span={12} style={{ minWidth: 160 }}>
              {renderPivotItem({ pivotOver, axis: "x" })}
            </Col>

            <Col span={12} style={{ minWidth: 160 }}>
              {renderPivotItem({ pivotOver, axis: "y" })}
            </Col>
          </Row>

          <Divider style={{ margin: 0 }} />
          <div style={{ padding: "8px" }}>
            <Checkbox disabled defaultChecked={pivotOver.fillMissingDates}>
              Fill Missing Dates
            </Checkbox>
          </div>
        </>
      )}
    </>
  );
});
