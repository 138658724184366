import React from "react";

// antd
import { AutoComplete, Input, SelectProps } from "antd";
import { DefaultOptionType } from "antd/es/select";

// hooks
import { useAppContext } from "@src/contexts/app-context";
import { useLocalStorage } from "@src/hooks/use-localstorage";

// configs
import { NAME_STORAGE } from "@src/config/storage";
import { sortBy } from "@src/helpers/sort-by";
import { IProduct } from "@src/types/user";

type IProps = {
  product?: IProduct;
  setProduct: React.Dispatch<React.SetStateAction<any>>;
  setIsEditing?: (value: boolean) => void;
  isEditing?: boolean;
};

export const ProductAutoComplete = ({ product, setProduct, setIsEditing, isEditing }: IProps) => {
  // states
  const [options, setOptions] = React.useState<SelectProps<object>["options"]>([]);
  const [value, setValue] = React.useState("");
  const [openAutoComplete, setOpenAutoComplete] = React.useState<boolean>(false);
  // refs
  const optionsRef = React.useRef<SelectProps<object>["options"]>([]);
  const searchBoxRef = React.useRef<any>(null);

  // hooks
  const [, saveProductStorage] = useLocalStorage(NAME_STORAGE.PRODUCT);
  const { products } = useAppContext();

  const handleSearch = (value: string) => {
    const optionsFiltered = [...(optionsRef.current || [])];
    const searchResult = optionsFiltered.filter((option) => {
      if (option.name) {
        const name = `${option.name} - ${option?.productCode || ""}`;
        return name.toLowerCase().includes(value.toLowerCase());
      }
      return false;
    });
    setOptions(value ? searchResult : optionsFiltered);
  };

  React.useEffect(() => {
    if (!(searchBoxRef.current && isEditing)) return;
    const timeout = setTimeout(() => {
      searchBoxRef.current?.select();
      setOpenAutoComplete(true);
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [isEditing]);

  const handleSelect = (_: string, option: DefaultOptionType) => {
    // close product autocomplete
    setOpenAutoComplete(false);
    setIsEditing?.(false);
    const productItem = products.find((product) => product.productCode === option.productCode) || null;

    if (!productItem) return;

    setValue("");
    setProduct(option);
    saveProductStorage(productItem);
  };

  const onChange = (value: string) => {
    setValue(value);
  };

  React.useEffect(() => {
    if (!products) return;
    const options = sortBy(products, "name").map((product) => {
      return {
        value: product.productCode,
        title: product.name,
        ...product,
        label: (
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <div className="w-[44px] h-[44px] relative mr-4">
                <img
                  className="rounded-lg w-[40px] h-[40px] object-cover"
                  src={product.icon}
                  alt="Product application"
                />
              </div>
              <div>
                {product.name} - {product.productCode}
              </div>
            </div>
          </div>
        ),
      };
    });
    optionsRef.current = options;
    setOptions(options);
  }, [product?.productCode, products]);

  return (
    <AutoComplete
      allowClear
      popupMatchSelectWidth={500}
      options={options}
      onSearch={handleSearch}
      onSelect={handleSelect}
      value={value}
      onChange={onChange}
      open={openAutoComplete}
      onBlur={() => {
        setOpenAutoComplete(false);
        setIsEditing?.(false);
      }}
    >
      <Input
        placeholder="Search products"
        ref={searchBoxRef}
        onClick={() => {
          setOpenAutoComplete(true);
        }}
      />
    </AutoComplete>
  );
};
