import React from "react";
import { createContext, PropsWithChildren, useContext } from "react";
import { Node, Edge } from "reactflow";
import dagre from "dagre";

// types
import {
  IExplorerNode,
  IFlowConfig,
  IFlowConfigItem,
  IMultiStepModal,
  IMultiStepQueryContextType,
  INodeItem,
  TYPE_MODAL,
} from "@src/types/explorer";

// hooks
import { useLocalStorage } from "@src/hooks/use-localstorage";

// config
import { NAME_STORAGE } from "@src/config/storage";
import { ADAPTER_QUERY_BUILDER } from "@src/config/query-builder";

// context
import { useAppContext } from "./app-context";

// adapter
import { ReactFlowAdapter } from "@src/adapter";
import { message } from "antd";

export const MultiStepQueryContext = createContext<IMultiStepQueryContextType>({} as IMultiStepQueryContextType);

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 200;
const nodeHeight = 100;

const getLayoutedElements = (nodes: Node[], edges: Edge[], direction = "TB") => {
  dagreGraph.setGraph({ rankdir: direction });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node: any) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = direction === "TB" ? "top" : "left";
    node.sourcePosition = direction === "TB" ? "bottom" : "right";

    node.position = {
      x: nodeWithPosition.x,
      y: nodeWithPosition.y,
    };

    return node;
  });

  return { nodes, edges };
};

export const MultiStepQueryContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  // states
  const [collapseQuerySidebar, setCollapseQuerySidebar] = React.useState<IMultiStepModal>({
    isOpen: false,
    type: "create",
  });
  const [explorerNode, setExplorerNode] = React.useState<IExplorerNode | null>(null);
  const [nodeType, setNodeType] = React.useState(ADAPTER_QUERY_BUILDER.cube.value);
  const [nodeHeight, setNodeHeight] = React.useState(200);
  const [timestamps, setTimestamp] = React.useState(Date.now());
  const [visualization, setVisualization] = React.useState<any>(null);
  const [isCollapsedVisualization, setIsCollapsedVisualization] = React.useState(false);
  const [direction, setDirection] = React.useState("TB");
  const [builderType, setBuilderType] = React.useState("QUERY_BUILDER"); // ["QUERY_BUILDER", "JSON_EDITOR"]
  // hooks
  const [messageApi, contextHolder] = message.useMessage();
  // storage
  const [nodeBase, setNodeBase] = useLocalStorage<any>(NAME_STORAGE.NODE_BASE, null);
  const [nodeBaseGenerated, setNodeGenerated] = useLocalStorage<any>(NAME_STORAGE.NODE_GENERATED, null);
  // context
  const { product } = useAppContext();
  // reactflow
  const [initialNodes, setInitialNodes] = React.useState<Node[]>([]);
  const [initialEdges, setInitialEdges] = React.useState<Edge[]>([]);
  const nodeBaseItem: IFlowConfigItem | null = nodeBase?.[product?.productCode || ""] || null;
  const nodeBaseGeneratedItem: IFlowConfigItem | null = nodeBaseGenerated?.[product?.productCode || ""] || null;

  const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(initialNodes, initialEdges, direction);

  // initialize nodes
  React.useEffect(() => {
    if (nodeBaseItem) {
      const keys = Object.keys(nodeBaseItem);
      const nodes: any = [];
      keys.forEach((key) => {
        const newNode = {
          id: key,
          data: { label: key },
          type: "explorerNode",
          position: { x: 0, y: 0 },
          connectable: false,
        };
        nodes.push(newNode);
      });
      setInitialNodes(nodes);
      const reactflowAdapter = new ReactFlowAdapter();
      const initialEdges = reactflowAdapter.initializeEdges(nodeBaseGeneratedItem);
      setInitialEdges(initialEdges);
    }
  }, [nodeBaseItem, nodeBaseGeneratedItem]);

  const updateMethodsNodeBaseConfig = () => ({
    add: (nodeBaseItem: IFlowConfigItem) => {
      if (product) {
        setNodeBase((prev: IFlowConfigItem) => {
          return {
            ...prev,
            [product.productCode]: {
              ...(prev?.[product.productCode] || {}),
              ...nodeBaseItem,
            },
          };
        });
      }
    },
    remove: (name: string) => {
      if (!product) return;
      const clonedNodeBaseItem = { ...nodeBaseItem };
      delete clonedNodeBaseItem[name];
      setNodeBase((prevState: any) => ({
        ...prevState,
        [product.productCode]: clonedNodeBaseItem,
      }));
    },
  });

  const updateMethodsNodeGeneratedConfig = () => ({
    add: (nodeBaseGeneratedItem: IFlowConfigItem) => {
      if (product) {
        setNodeGenerated((prev: any) => {
          return {
            ...prev,
            [product.productCode]: {
              ...(prev?.[product.productCode] || {}),
              ...nodeBaseGeneratedItem,
            },
          };
        });
      }
    },
    remove: (name: string) => {
      if (!product) return;
      const clonedNodeBaseGeneratedItem = { ...nodeBaseGeneratedItem };
      delete clonedNodeBaseGeneratedItem[name];
      setNodeGenerated((prevState: any) => ({
        ...prevState,
        [product.productCode]: clonedNodeBaseGeneratedItem,
      }));
    },
  });

  function prepareRenderProps() {
    const updateMethodsNode = () => ({
      add: (nodeBaseItem: IFlowConfigItem, nodeBaseGeneratedItem: IFlowConfigItem) => {
        if (product) {
          updateMethodsNodeBaseConfig().add({ ...nodeBaseItem });
          updateMethodsNodeGeneratedConfig().add({ ...nodeBaseGeneratedItem });
        }
      },
      setNode: (nodeItem: IExplorerNode | null) => {
        setExplorerNode(nodeItem);
      },
      update: (name: string) => {
        if (!nodeBaseItem) return;
        const nodeItem = nodeBaseItem?.[name] || null;
        setExplorerNode(nodeItem);
        setNodeType(nodeItem.adapter_name); // set node type
        updateMethodsQuerySidebar().open(true, "edit"); // set mode edit
        setBuilderType("QUERY_BUILDER"); // set builder type
      },
      remove: (node: INodeItem): boolean => {
        if (!nodeBaseGeneratedItem) return false;
        const isDependency = layoutedEdges.some((edge) => edge.source === node.data.label);
        if (isDependency) {
          messageApi.open({
            type: "error",
            content: "Cannot delete node with dependencies",
          });
          return false;
        }
        updateMethodsNodeBaseConfig().remove(node.data.label);
        updateMethodsNodeGeneratedConfig().remove(node.data.label);

        return true;
      },
      getNodeBaseConfig: (): IFlowConfig | null => {
        if (!product || !nodeBaseItem) return null;
        return nodeBase[product.productCode] || null;
      },
      getMeta: (name: string) => {
        if (!nodeBaseGeneratedItem) return;
        return nodeBaseGeneratedItem[name].meta;
      },
      setVisualization: (visualization: any | null) => {
        setVisualization(visualization);
      },
      setLayoutDirection: (direction: string) => {
        setDirection(direction);
      },
      getDirection: () => {
        return direction;
      },
      setBuilderType: (type: string) => {
        setBuilderType(type);
      },
      getBuilderType: () => {
        return builderType;
      },
    });

    const updateMethodsQuerySidebar = () => ({
      open: (isOpen: boolean, type: TYPE_MODAL = "create") => {
        setCollapseQuerySidebar({
          isOpen,
          type,
        });
      },
      close: () => {
        setCollapseQuerySidebar((prevState) => ({
          ...prevState,
          isOpen: false,
        }));
      },
    });

    const updateNodeType = (type: string) => {
      setNodeType(type);
    };

    const updateNodeHeight = (height: number) => {
      setNodeHeight(height);
    };

    const forceChangeTimestamp = () => {
      setTimestamp(Date.now());
    };

    const openCollapseVisualization = (isCollapsed: boolean) => {
      setIsCollapsedVisualization(isCollapsed);
    };

    return {
      // states
      collapseQuerySidebar,
      nodeType,
      explorerNode,
      timestamps,
      nodeBaseItem,
      nodeBaseGeneratedItem,
      layoutedNodes,
      layoutedEdges,
      visualization,
      isCollapsedVisualization,
      // actions
      messageApi,
      forceChangeTimestamp,
      updateNodeHeight,
      updateNodeType,
      updateMethodsQuerySidebar: updateMethodsQuerySidebar(),
      updateMethodsNode: updateMethodsNode(),
      openCollapseVisualization,
    };
  }

  return (
    <MultiStepQueryContext.Provider
      value={{
        ...prepareRenderProps(),
      }}
    >
      {contextHolder}
      {children}
    </MultiStepQueryContext.Provider>
  );
};

export const useMultiStepQuery = () => useContext(MultiStepQueryContext);
