import { useCubeQuery } from "@cubejs-client/react";
import PivotGroup from "@src/components/query-builder/pivot-group";
import { useExploreContext } from "@src/contexts/explore-context";
import { Button, Card, message, Tooltip } from "antd";
import React from "react";
import { CSVLink } from "react-csv";
import { CopyOutlined, DownloadOutlined, UndoOutlined } from "@ant-design/icons";
import { ChartRendererNew } from "@src/pages/dashboard/components/chart-renderer";
import { FormInstance } from "antd/lib/form";

type ChartResultProps = {
  mode: "create" | "edit";
  layout: any;
  pivotConfig: any;
  setPivotConfig?: React.Dispatch<any>;
  form?: FormInstance<any>;
};
export const ChartResult: React.FC<ChartResultProps> = ({ mode, layout, pivotConfig, setPivotConfig, form }) => {
  const { query, product } = useExploreContext();

  const [messageApi, contextHolder] = message.useMessage();
  const [skip, setSkip] = React.useState(mode === "create");
  const renderProps = useCubeQuery(query, {
    skip,
  });
  const previousQuery = renderProps.previousQuery;
  const shouldChange = JSON.stringify(query) !== JSON.stringify(previousQuery);
  const onRefresh = () => {
    if (form === undefined) {
      setSkip(false);
      return;
    }
    form?.validateFields(["custom-time-range"], {}).then(() => {
      setSkip(false);
    });
  };

  React.useEffect(() => {
    if (!renderProps.isLoading && renderProps.resultSet) {
      setSkip(true);
    }
  }, [renderProps.isLoading, renderProps.resultSet]);

  function getResultSet() {
    return renderProps.resultSet;
  }
  const tablePivot = getResultSet()?.tablePivot() || [];

  function copyDataPivot() {
    navigator.clipboard.writeText(JSON.stringify(tablePivot, null, 2));
    messageApi.open({
      type: "success",
      content: "Copy success!",
    });
  }

  return (
    <div className="h-full ">
      {contextHolder}
      <Card
        title={
          <div className="flex items-center justify-between">
            <div className="flex space-x-2">
              <span>Result</span>
              <PivotGroup layout={layout} setPivotConfig={setPivotConfig} />
            </div>
            <div>
              <Tooltip color="gold" title={`${!product ? "Please select product before refresh result!" : ""}`}>
                <Button
                  icon={<UndoOutlined />}
                  className={`m-2 ${shouldChange ? "bg-yellow-300" : ""}`}
                  onClick={onRefresh}
                  disabled={Boolean(!product)}
                >
                  Refresh result
                </Button>
              </Tooltip>
              {renderProps.resultSet && (
                <>
                  <Tooltip title="Copy data">
                    <Button
                      type="text"
                      disabled={tablePivot.length >= 1000}
                      style={{ color: tablePivot.length >= 1000 ? "rgba(0, 0, 0, 0.25)" : "#1677ff" }}
                      icon={<CopyOutlined />}
                      onMouseDown={copyDataPivot}
                    />
                  </Tooltip>
                  <Tooltip title="Export CSV">
                    <CSVLink
                      filename={layout.name}
                      data={tablePivot}
                      headers={getResultSet()
                        ?.tableColumns()
                        .map((c: any) => {
                          return { label: c.title, key: c.key };
                        })}
                    >
                      <Button
                        type="text"
                        style={{ color: "#1677ff" }}
                        icon={<DownloadOutlined />}
                        onMouseDown={() => {
                          messageApi.open({
                            type: "success",
                            content: "Export CSV success!",
                          });
                        }}
                      />
                    </CSVLink>
                  </Tooltip>
                </>
              )}
            </div>
          </div>
        }
        size="small"
        className="h-full"
        classNames={{
          body: "h-[calc(100%-40px)]",
        }}
      >
        <div className="flex h-full space-x-4">
          <div className="w-full h-full overflow-y-auto">
            <ChartRendererNew vizState={{ chartType: "table", pivotConfig }} renderProps={renderProps} />
          </div>
        </div>
      </Card>
    </div>
  );
};
