import { useCubeQuery } from "@cubejs-client/react";
import { useExploreContext } from "@src/contexts/explore-context";
import { Card } from "antd";
import React from "react";
import { ChartRendererNew } from "@src/pages/dashboard/components/chart-renderer";
import PivotGroupVisualization from "./pivot-group-visualization";

type ChartResultProps = {
  mode: "create" | "edit";
  layout: any;
  pivotConfig?: any;
  setPivotConfig?: React.Dispatch<any>;
};
export const ChartResultVisualization: React.FC<ChartResultProps> = ({ mode, layout, pivotConfig, setPivotConfig }) => {
  const { query } = useExploreContext();

  const [skip, setSkip] = React.useState(mode === "create");
  const renderProps = useCubeQuery(query, {
    skip,
  });

  React.useEffect(() => {
    if (!renderProps.isLoading && renderProps.resultSet) {
      setSkip(true);
    }
  }, [renderProps.isLoading, renderProps.resultSet]);

  return (
    <div className="h-full ">
      <Card
        title={
          <div className="flex items-center justify-between">
            <div className="flex space-x-2">
              <span>Result</span>
              <PivotGroupVisualization layout={layout} setPivotConfig={setPivotConfig} />
            </div>
          </div>
        }
        size="small"
        className="h-full"
        classNames={{
          body: "h-[calc(100%-40px)]",
        }}
      >
        <div className="flex h-full space-x-4">
          <div className="w-full h-full overflow-y-auto">
            <ChartRendererNew vizState={{ chartType: "table", pivotConfig }} renderProps={renderProps} />
          </div>
        </div>
      </Card>
    </div>
  );
};
