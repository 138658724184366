import React from "react";
import { DatePicker, Form } from "antd";
import { config } from "@src/config";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;
type IProps = {
  value?: string[];
  onChange: (_: any, dateStrings: [string, string]) => void;
  disabled?: boolean;
};

function TimeDateRanger({ value = [], onChange, disabled = false }: IProps) {
  return (
    <Form.Item
      className="m-0 "
      name="custom-time-range"
      initialValue={[
        value?.length > 0 ? dayjs(value[0], config.DATE_FORMAT) : null,
        value?.length > 0 ? dayjs(value[1], config.DATE_FORMAT) : null,
      ]}
      rules={[
        {
          validator: async (_, value) => {
            if (value && value.length === 2 && value[0] && value[1]) {
              return Promise.resolve();
            } else {
              return Promise.reject("Please select time range");
            }
          },
        },
      ]}
    >
      <RangePicker disabled={!!disabled} onChange={onChange} />
    </Form.Item>
  );
}

export default TimeDateRanger;
