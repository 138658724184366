import { Button, Form, FormInstance, Tabs } from "antd";
import { EventParamContentTabForm } from "./event-name-tab-form";
import { AnalyticEventsTabLabel } from "./analytic-events-tab-label";
import { css } from "@emotion/react";
import { PlusCircleOutlined } from "@ant-design/icons";
import { magenta } from "@ant-design/colors";

export const AnalyticEventForm: React.FC<{ form: FormInstance<object> }> = ({ form }) => {
  return (
    <Form.List name={"analytic_events"}>
      {(fields, { add, remove }) => (
        <div className="flex flex-wrap">
          <Tabs
            className="overflow-auto"
            css={css`
              .ant-tabs-tab,
              .ant-tabs-tab-btn {
                width: 100%;
              }
              .ant-tag {
                transition: none;
              }
              .ant-tabs-tab-active {
                .ant-tag {
                  border-width: 2px;
                  border-color: ${magenta[3]};
                }
              }
            `}
            tabBarStyle={{
              width: 270,
            }}
            tabPosition="left"
            size={"small"}
            tabBarGutter={0}
            indicator={{
              size: 0,
            }}
            items={[
              {
                label: (
                  <Button
                    onClick={(e) => {
                      add({
                        event_name: "",
                        is_new: true,
                      });
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    css={css`
                      background-color: ${magenta[3]};
                      color: white;
                      border-color: ${magenta[3]};
                      &:hover {
                        color: ${magenta[3]} !important;
                        border-color: ${magenta[3]}!important;
                      }
                    `}
                    className="w-full text-[12px]"
                    size="small"
                    icon={<PlusCircleOutlined />}
                  >
                    Add event name
                  </Button>
                ),
                key: "addBtn",
                children: "",
              },
              ...fields.reverse().map(({ key, name }) => {
                return {
                  label: <AnalyticEventsTabLabel name={name} remove={remove} form={form} />,
                  key: key.toString(),
                  children: <EventParamContentTabForm form={form} name={name} />,
                };
              }),
            ]}
          />
        </div>
      )}
    </Form.List>
  );
};
