import { useCreate, useNavigation, useParsed } from "@refinedev/core";
import { useLocalStorage } from "../use-localstorage";
import React from "react";
import { mappingLabelToExpType } from "@src/constant/explore/mapping-title-new-explore";

export const useAutoSaveExistingConfig = () => {
  const { push } = useNavigation();
  const { pathname } = useParsed();

  const [autoSave, setAutoSave] = useLocalStorage("start_auto_save");

  const [chart] = useLocalStorage("charts");
  const [_, setChartBackup] = useLocalStorage("charts_backup");
  const { mutateAsync: createExplore, isLoading } = useCreate();

  React.useEffect(() => {
    if (autoSave || !chart) return;
    setAutoSave("true");
    if (!chart) return;
    setChartBackup(chart);
    const _chart = chart as Record<string, any>;

    const chartsConfig: any = [];
    Object.keys(_chart).forEach((key) => {
      Object.values(_chart[key]).forEach((value) => {
        chartsConfig.push(value);
      });
    });

    async function createExploreWithChartsConfigLocal(_chartsConfig: any[]) {
      let i = 0;
      for (const chartConfig of _chartsConfig) {
        const productCode = chartConfig.product_code ?? chartConfig.productCode;
        if (!productCode) {
          continue;
        }
        const {
          vizState: { chartType, pivotConfig, query },
        } = chartConfig;
        await createExplore({
          resource: "exploration/fb-exploration",
          values: {
            title: chartConfig.name,
            description: chartConfig.name,
            product_code: productCode,
            exp_type: mappingLabelToExpType[chartConfig?.type ?? "Firebase exploration"],
            chart_type: chartType,
            pivot_config: pivotConfig,
            ...query,
            time_dimensions: query.timeDimensions,
          },
        });
        ++i;
      }
      if (i === _chartsConfig.length) {
        window.localStorage.removeItem("charts");
        window.localStorage.removeItem("start_auto_save");
        if (pathname?.startsWith("/explorations/firebase/edit/")) {
          push("/explorations");
        }
      }
    }

    createExploreWithChartsConfigLocal(chartsConfig);
  }, [chart]);

  return {
    isLoading,
  };
};
