import { Result } from "antd";
import React from "react";

function PathAnalysis() {
  return (
    <div>
      <Result title="Path Analysis" subTitle="This page is under implement. Please come back later." />
    </div>
  );
}

export default PathAnalysis;
