import { useCreate, useNavigation, useOne } from "@refinedev/core";
import React from "react";

export const useDuplicateFirebaseExplore = ({
  id,
  redirect = true,
  replace = true,
  cb,
}: {
  id?: string;
  redirect?: boolean;
  replace?: boolean;
  cb?: () => void;
}) => {
  const navigation = useNavigation();
  const [_id, setId] = React.useState<string | undefined>(id);

  const { refetch } = useOne({
    id: _id,
    resource: `exploration/fb-exploration`,
    queryOptions: {
      enabled: false,
    },
  });

  const { mutateAsync: createExplore } = useCreate();

  const onDuplicate = () => {
    refetch().then((data) => {
      if (!data?.data) return;
      const { title, ...restData } = data?.data?.data as any;

      delete restData.id;
      delete restData.owner;
      delete restData.created_datetime;
      delete restData.updated_datetime;

      createExplore({
        resource: "exploration/fb-exploration",
        values: {
          title: title + " COPY",
          ...restData,
        },
        successNotification: () => {
          return {
            type: "success",
            message: "Clone firebase exploration successfully!",
          };
        },
        errorNotification: () => {
          return {
            type: "error",
            message: "Clone firebase exploration failed!",
          };
        },
      }).then((data: any) => {
        cb && cb();
        if (redirect) {
          const newId = data?.data?.id;
          replace
            ? navigation.replace(`/explorations/firebase/edit/${newId}`)
            : navigation.push(`/explorations/firebase/edit/${newId}`);
        }
      });
    });
  };
  const handleDuplicate = async ({ id }: { id?: string }) => {
    if (!id) {
      onDuplicate();
      return;
    }
    setId(id);
  };

  React.useEffect(() => {
    if (!_id) return;
    if (_id === id) return;
    onDuplicate();
  }, [_id]);

  return { handleDuplicate };
};
