import { Draggable } from "react-beautiful-dnd";
import { DragOutlined } from "@ant-design/icons";
import { Typography } from "antd";

type IProps = {
  id: string;
  index: number;
};

export default function DropItem({ id, index }: IProps) {
  return (
    <Draggable draggableId={id} index={index}>
      {({ draggableProps, dragHandleProps, innerRef }) => (
        <div
          ref={innerRef}
          {...draggableProps}
          {...dragHandleProps}
          style={{
            ...draggableProps.style,
            border: `1px dashed rgba(213,213,226,1)`,
            borderRadius: 4,
            padding: "5px 12px",
            lineHeight: "22px",
          }}
        >
          <Typography.Text ellipsis style={{ maxWidth: "100%" }}>
            <DragOutlined style={{ marginRight: 8 }} />
            {id}
          </Typography.Text>
        </div>
      )}
    </Draggable>
  );
}
