import { PropsWithChildren } from "react";
import { css } from "@emotion/react";
import { blue } from "@ant-design/colors";
import { Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { capitalize } from "lodash";
import { getModeOfLocation } from "@src/util/fb-exploration/get-mode-of-location";

interface MemberTagProps {
  member: any;
  onRemoveMember: (member: any) => void;
  stopColor?: number;
}

const MemberTagBase: React.FC<PropsWithChildren<MemberTagProps>> = ({
  children,
  member,
  onRemoveMember,
  stopColor = 4,
}) => {
  const { isViewMode } = getModeOfLocation();
  const parent = capitalize(member.name?.split(".")[0]) || member.title;

  return (
    <div key={member.title} className="mb-2 mr-2">
      <div
        className={`rounded-md text-white border-[1px] py-[0.3rem] px-2 border-none flex items-center`}
        css={css`
          background-color: ${blue[stopColor]};
        `}
      >
        <span>{parent}</span>
        <span
          className="px-2 py-[0.1rem] rounded ml-1"
          css={css`
            background-color: ${blue[stopColor + 2]};
          `}
        >
          {member.shortTitle}
        </span>
        {member.type === "time" && children}
        <Button
          disabled={!!isViewMode}
          onClick={() => onRemoveMember(member)}
          size="small"
          icon={<CloseOutlined className="mt-1" />}
          className="border-0 p-0 ml-2"
          css={css`
            background-color: ${blue[3]};
            color: red !important;
            &:hover {
              background-color: ${blue[1]} !important;
              color: red !important;
            }
          `}
        />
      </div>
    </div>
  );
};

export default MemberTagBase;
