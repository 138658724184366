import { PlusCircleFilled } from "@ant-design/icons";
import { List, useModal } from "@refinedev/antd";
import { Button } from "antd";

import React from "react";
import { ExploreList } from "./component/list/explore-list";
import { PopupNewExplore } from "./component/list/popup-new-explore";

export const ListExplore: React.FC = () => {
  const { show, modalProps } = useModal();

  return (
    <>
      <PopupNewExplore modalProps={modalProps} />

      <List
        title={
          <div>
            <Button type="primary" size="large" icon={<PlusCircleFilled />} onClick={show}>
              Add new explore
            </Button>
          </div>
        }
        headerProps={{
          extra: false,
        }}
      >
        <ExploreList />
      </List>
    </>
  );
};
