export const sortBy = (arr: any[], key?: string) => {
  if (!arr) return arr;
  return arr.sort((a, b) => {
    const keyA = key ? (typeof a[key] === "string" ? a[key].toLowerCase() : a[key]) : a.toLowerCase();
    const keyB = key ? (typeof b[key] === "string" ? b[key].toLowerCase() : b[key]) : b.toLowerCase();
    if (keyA < keyB) return -1;
    if (keyA > keyB) return 1;
    return 0;
  });
};
