import { ADAPTER_QUERY_BUILDER } from "@src/config/query-builder";
import { IBaseQuery, IBodyConfigs, IBodyDryRun, IExplorerNode, ISetCubeQuery, ISetSqlQuery } from "@src/types/explorer";

export default class MultiStepAdapter {
  public static instance: MultiStepAdapter = new MultiStepAdapter();
  public cubeQuery: IBaseQuery;
  public sqlQuery: IBaseQuery;

  constructor() {
    this.cubeQuery = {
      adapter_name: "",
      alias: "",
      model_config: {
        json_query: {},
        product_code: "",
      },
    };
    this.sqlQuery = {
      adapter_name: "",
      alias: "",
      model_config: {
        query: "",
      },
    };
  }

  public static getInstance(): MultiStepAdapter {
    if (this.instance === null) {
      this.instance = new MultiStepAdapter();
    }
    return this.instance;
  }

  public setCubeQuery({ adapter_name, alias, model_config }: ISetCubeQuery) {
    this.cubeQuery = {
      adapter_name,
      alias,
      model_config,
    };
    return this.cubeQuery;
  }

  public setSqlQuery({ adapter_name, alias, model_config }: ISetSqlQuery) {
    this.cubeQuery = {
      adapter_name,
      alias,
      model_config,
    };
    return this.cubeQuery;
  }

  public getBodyData({ alias, queryNodeBase, product_code, cubeQuery, sqlQuery, nodeType }: any) {
    let bodyData = {};
    let baseQuery = {
      adapter_name: "",
      alias: "",
      model_config: {},
    };

    switch (nodeType) {
      case ADAPTER_QUERY_BUILDER.cube.value: {
        baseQuery = this.setCubeQuery({
          adapter_name: ADAPTER_QUERY_BUILDER.cube.value,
          alias,
          model_config: {
            json_query: cubeQuery,
            product_code,
          },
        });

        bodyData = {
          configs: [...queryNodeBase, baseQuery],
          target_aliases: [alias],
        };

        break;
      }
      case ADAPTER_QUERY_BUILDER["dbt-like"].value: {
        baseQuery = this.setSqlQuery({
          adapter_name: ADAPTER_QUERY_BUILDER["dbt-like"].value,
          alias,
          model_config: {
            query: sqlQuery,
          },
        });
        bodyData = {
          configs: [...queryNodeBase, baseQuery],
          target_aliases: [alias],
        };
        break;
      }
      default:
        break;
    }

    return {
      bodyData,
      baseQuery,
    };
  }

  public getResultDryrun({ node, name }: IBodyDryRun) {
    let nodeBaseItem = {};
    const nodeGeneratedItem = {
      [node.alias]: {
        ...node,
      },
    };
    switch (node.adapter_name) {
      case ADAPTER_QUERY_BUILDER.cube.value: {
        nodeBaseItem = {
          [node.alias]: {
            adapter_name: node.adapter_name,
            alias: node.alias,
            name,
            model_config: {
              json_query: node.model_config.json_query,
              product_code: node.model_config.product_code,
            },
          },
        };

        break;
      }
      case ADAPTER_QUERY_BUILDER["dbt-like"].value: {
        nodeBaseItem = {
          [node.alias]: {
            adapter_name: node.adapter_name,
            alias: node.alias,
            name,
            model_config: {
              query: node.model_config.query,
            },
          },
        };
        break;
      }
      default:
        break;
    }

    return {
      nodeBaseItem,
      nodeGeneratedItem,
    };
  }

  public getBodyConfigs({ nodeBaseItem, alias, nodeType, query }: IBodyConfigs) {
    const configs = JSON.parse(JSON.stringify(nodeBaseItem || {}));
    const nodeItem = configs[alias];
    if (!nodeItem) return { configs };

    switch (nodeType) {
      case ADAPTER_QUERY_BUILDER.cube.value: {
        configs[alias] = {
          ...configs[alias],
          model_config: {
            ...configs[alias].model_config,
            json_query: query,
          },
        };

        break;
      }
      case ADAPTER_QUERY_BUILDER["dbt-like"].value: {
        configs[alias] = {
          ...configs[alias],
          model_config: {
            query,
          },
        };
        break;
      }
      default:
        break;
    }
    return { configs };
  }

  public getFormNode(explorerNode: IExplorerNode | null) {
    const res = {
      alias: explorerNode?.alias || "",
      name: explorerNode?.name || "",
      sql: "",
    };
    switch (explorerNode?.adapter_name) {
      case ADAPTER_QUERY_BUILDER["dbt-like"].value: {
        res.sql = explorerNode.model_config.query || "";
        break;
      }
      default:
        break;
    }
    return res;
  }
}
