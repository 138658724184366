import { useCubeQuery } from "@cubejs-client/react";
import { Form, Select } from "antd";
import { FormInstance } from "antd/lib/form/Form";

export const AbaIdFilter: React.FC<{ form: FormInstance }> = ({ form }) => {
  const productTokenWatch = Form.useWatch("product_token");

  const { resultSet, isLoading } = useCubeQuery(
    {
      dimensions: ["user_ab_testing.aba_id"],
    },
    { skip: !productTokenWatch },
  );

  const options = isLoading
    ? []
    : resultSet?.rawData().map((item: any) => {
        return {
          label: `Aba Id: ${item["user_ab_testing.aba_id"]}`,
          value: item["user_ab_testing.aba_id"],
        };
      });

  return (
    <Form.Item label="Aba Id" name="aba_id" className="ml-2" required>
      <Select
        loading={isLoading}
        disabled={!productTokenWatch}
        allowClear
        placeholder="Select Aba Id"
        options={options}
        showSearch
        filterOption={(input, option) => {
          const { label, value } = option as any;
          if (!value || !label) return false;
          return (
            label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
            value.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
          );
        }}
        style={{
          width: 200,
        }}
      />
    </Form.Item>
  );
};
