import { SaveButton } from "@refinedev/antd";
import { ButtonProps, Drawer, DrawerProps, Form, FormInstance, FormProps, Spin } from "antd";
import { ImportProduct } from "@src/components/import/import-product";
import { useImportProduct } from "@src/hooks/use-import-product";
import React from "react";
import { GeneralBodyForm } from "./components/form/general-body-form";

interface ProductEditProps {
  drawerProps: DrawerProps;
  saveButtonProps: ButtonProps & {
    onClick: () => void;
  };
  formProps: FormProps<object> & {
    form: FormInstance<object>;
  };
  close: () => void;
  isLoading: boolean;
}

export const ProductEdit: React.FC<ProductEditProps> = ({
  formProps,
  saveButtonProps,
  drawerProps,
  isLoading,
  close,
}) => {
  const { data, setData } = useImportProduct();
  React.useEffect(() => {
    formProps.form.resetFields();
  }, [data, formProps.form]);
  return (
    <Drawer
      {...drawerProps}
      title={
        <div className="flex justify-between items-center">
          <span>Edit product</span>
          <ImportProduct setData={setData} />
        </div>
      }
      width={1000}
      footer={
        <div className="w-full flex justify-end/">
          <SaveButton {...saveButtonProps} />
        </div>
      }
      onClose={() => {
        close();
        setData(undefined);
      }}
    >
      <Spin spinning={isLoading}>
        <Form
          layout="vertical"
          {...formProps}
          onFinish={(e) => {
            formProps.onFinish?.(e);
            formProps?.form?.resetFields();
          }}
          initialValues={data || formProps.initialValues}
        >
          <GeneralBodyForm form={formProps.form} />
        </Form>
      </Spin>
    </Drawer>
  );
};
