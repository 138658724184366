import { Result } from "antd";
import React from "react";

function SavedExploration() {
  return (
    <div>
      <Result title="Saved Exploration" subTitle="This page is under implement. Please come back later." />
    </div>
  );
}

export default SavedExploration;
