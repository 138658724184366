import React from "react";
import { useAppContext } from "@src/contexts/app-context";
import { Form, Input, Select } from "antd";
import { FormInstance } from "antd/lib/form/Form";

export const ProductFilter: React.FC<{ form: FormInstance<any> }> = ({ form }) => {
  const { products } = useAppContext();

  const renderLabelProduct = (product: any) => {
    return (
      <div className="flex items-center">
        <img src={product.icon} className="w-4 h-4 rounded-[2px] mr-2" />
        {product.name} - {product.productCode}
      </div>
    );
  };

  const options = React.useMemo(() => {
    return products.map((product) => ({
      label: renderLabelProduct(product),
      value: product.productCode,
      title: product.name,
    }));
  }, [products]);

  return (
    <div>
      <Form.Item label="Product" name="product_code" required>
        <Select
          options={options}
          placeholder="Select product"
          showSearch
          filterOption={(input, option) => {
            const { title, value } = option as any;
            if (!value || !title) return false;
            return (
              title.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              value.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
          }}
          allowClear
          style={{ width: 350 }}
          onChange={() => {
            form.setFieldsValue({ aba_id: [], product_token: undefined });
          }}
        />
      </Form.Item>
      <Form.Item name="product_token" hidden>
        <Input />
      </Form.Item>
    </div>
  );
};
