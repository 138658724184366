import { useCubeQuery } from "@cubejs-client/react";
import { SelectWithSpinAndCustomMaxTag } from "@src/components/select/SelectWithCustomMaxTag";
import { Form } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import React from "react";

export const VariantFilter: React.FC<{ form: FormInstance }> = ({ form }) => {
  const abaIdWatch = Form.useWatch("aba_id");
  const isHasAbaId = abaIdWatch && abaIdWatch?.length > 0;

  const { resultSet, isLoading } = useCubeQuery(
    {
      dimensions: ["user_ab_testing.exp_group"],
      filters: [
        {
          member: "user_ab_testing.aba_id",
          operator: "equals",
          values: [abaIdWatch?.toString()],
        },
      ],
    },
    {
      skip: !isHasAbaId,
    },
  );

  const options = React.useMemo(() => {
    return (
      resultSet?.rawData().map((item: any) => {
        return {
          label: "Variant: " + item["user_ab_testing.exp_group"],
          value: item["user_ab_testing.exp_group"],
        };
      }) ?? []
    );
  }, [resultSet?.rawData()]);

  React.useEffect(() => {
    form.setFieldValue(
      "variants",
      options?.map((item: any) => item.value),
    );
  }, [options]);

  React.useEffect(() => {
    if (isHasAbaId) return;
    form.setFieldsValue({ variants: [] });
  }, [isHasAbaId]);

  return (
    <Form.Item label="Variants" name="variants" className="ml-2">
      <SelectWithSpinAndCustomMaxTag
        loading={isLoading}
        disabled={!isHasAbaId}
        mode="multiple"
        allowClear
        placeholder="Select Variant"
        options={isLoading || !isHasAbaId ? [] : options}
        style={{
          width: 300,
        }}
      />
    </Form.Item>
  );
};
