import { BarDatum, ResponsiveBar } from "@nivo/bar";
import { Radio } from "antd";
import React from "react";

interface NivoBarChartProps {
  dataSource: readonly BarDatum[];
  keys: string[];
}

export const NivoBarChart: React.FC<NivoBarChartProps> = ({ dataSource, keys }) => {
  const [groupMode, setGroupMode] = React.useState<"grouped" | "stacked">("stacked");
  return (
    <div className="h-full w-full">
      <div className="flex mb-4  text-[12px] justify-end">
        <div className="flex flex-col">
          <span className="font-bold mb-1">Group mode</span>
          <Radio.Group size="small" onChange={(e) => setGroupMode(e.target.value)} value={groupMode}>
            <Radio.Button className="text-[12px]" value={"stacked"}>
              Stacked
            </Radio.Button>
            <Radio.Button className="text-[12px]" value={"grouped"}>
              Grouped
            </Radio.Button>
          </Radio.Group>
        </div>
      </div>
      <div style={{ height: "90%", width: "100%" }}>
        <ResponsiveBar
          data={dataSource}
          keys={keys}
          indexBy={"x"}
          margin={{ top: 0, bottom: 100, left: 60 }}
          colors={{ scheme: "nivo" }}
          colorBy="id"
          tooltipLabel={(e) => {
            return e.id as string;
          }}
          groupMode={groupMode}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 45,
            legendOffset: 32,
            format: (e) => {
              return e.split(",")[0];
            },
          }}
        />
      </div>
    </div>
  );
};
