import { useAuth0 } from "@auth0/auth0-react";
import { AuthProvider } from "@refinedev/core";
import axios from "axios";

export const useAuth0Provider = () => {
  const { user, logout, getIdTokenClaims, isLoading } = useAuth0();
  console.log({ user });
  const auth0Provider: AuthProvider = {
    login: async () => {
      return {
        success: true,
      };
    },
    logout: async () => {
      logout({
        openUrl(url) {
          window.location.replace(url);
        },
      });
      return {
        success: true,
      };
    },
    onError: async (error) => {
      console.error(error);
      return { error };
    },
    check: async () => {
      try {
        const token = await getIdTokenClaims();
        if (token) {
          axios.defaults.headers.common = {
            Authorization: `Bearer ${token.__raw}`,
            "x-sat-user-token": `Bearer ${token.__raw}`,
          };
          return {
            authenticated: true,
          };
        } else {
          return {
            authenticated: false,
            error: {
              message: "Check failed",
              name: "Token not found",
            },
            redirectTo: "/login",
            logout: true,
          };
        }
      } catch (error: any) {
        return {
          authenticated: false,
          error: new Error(error),
          redirectTo: "/login",
          logout: true,
        };
      }
    },
    getPermissions: async () => null,
    getIdentity: async () => {
      if (user) {
        return {
          ...user,
          avatar: user.picture,
        };
      }
      return null;
    },
  };

  getIdTokenClaims().then((token) => {
    console.log({ token });
    if (token) {
      axios.defaults.headers.common = {
        Authorization: `Bearer ${token.__raw}`,
        "x-sat-user-token": `Bearer ${token.__raw}`,
      };
    }
  });

  return { auth0Provider, isLoading, axios };
};
