import { Result } from "antd";
import React from "react";

function DrillerAnalysis() {
  return (
    <div>
      <Result title="Driller Analysis" subTitle="This page is under implement. Please come back later." />
    </div>
  );
}

export default DrillerAnalysis;
