export const NAME_STORAGE = {
  ACCESS_TOKEN: "access_token",
  CUBE_META: "cube_meta",
  PRODUCT_CODE: "product_code",
  PRODUCT: "product",
  USER: "user",
  CHARTS: "charts",
  NODE_BASE: "node_base",
  NODE_GENERATED: "node_generated",
};
