import { Button, Form } from "antd";
import { FormInstance } from "antd/lib/form/Form";

interface SubmitButtonProps {
  form: FormInstance<any>;
}
export const SubmitButton: React.FC<SubmitButtonProps> = ({ form }) => {
  const productWatch = Form.useWatch("product_code", form);
  const timeRangeWatch = Form.useWatch("time_range", form);
  const customTimeRangeWatch = Form.useWatch("custom_time_range", form);
  const abaIdWatch = Form.useWatch("aba_id", form);

  const isHasAbaId = abaIdWatch && abaIdWatch?.length > 0;
  const isValidTimeRange =
    !!timeRangeWatch && (timeRangeWatch !== "custom" || (timeRangeWatch === "custom" && !!customTimeRangeWatch));
  const enoughFieldsRequired = !!productWatch && isValidTimeRange && isHasAbaId;

  return (
    <Form.Item label=" " className="ml-2">
      <Button type="primary" disabled={!enoughFieldsRequired} onClick={() => form.submit()}>
        Apply
      </Button>
    </Form.Item>
  );
};
