import React from "react";
import { createContext, useContext } from "react";
import { QueryBuilder } from "@cubejs-client/react";

export const WarehouseContext = createContext({} as any);

type IProps = {
  children: React.ReactNode;
};

export const WareHouseContextProvider: React.FC<IProps> = ({ children }) => {
  return (
    <QueryBuilder
      wrapWithQueryRenderer={false}
      disableHeuristics
      render={({ ...restProps }) => {
        return (
          <>
            <WarehouseContext.Provider value={{ ...restProps }}>{children}</WarehouseContext.Provider>
          </>
        );
      }}
    />
  );
};

export const useWarehouseContext = () => useContext(WarehouseContext);
