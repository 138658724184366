import React from "react";
import { Alert, Col, Row } from "antd";

// context
import { useWarehouseContext } from "@src/contexts/warehouse-context";

// helpers
import { playgroundActionUpdateMethods } from "@src/helpers/update-method-query";

// components
import MemberGroupDimensions from "@src/components/query-builder/v2/member-group-dimensions";
import MemberGroupMeasures from "@src/components/query-builder/v2/member-group-measures";
import { IUpdateMethods } from "@src/types/query-builder";
import MemberGroupSegments from "@src/components/query-builder/v2/member-group-segment";
import MemberGroupTime from "@src/components/query-builder/v2/member-group-time";
import MemberGroupFilter from "@src/components/query-builder/v2/member-group-filter";
import OrderGroup from "@src/components/query-builder/order-group";
import LimitGroup from "@src/components/query-builder/limit-group";

function BuilderForm() {
  const {
    query,
    updateQuery,
    isQueryPresent,
    measures,
    availableMeasures,
    updateMeasures,
    dimensions,
    availableDimensions,
    filters,
    updateFilters,
    timeDimensions,
    availableTimeDimensions,
    updateTimeDimensions,
    segments,
    availableSegments,
    updateSegments,
    error,
    isFetchingMeta,
  } = useWarehouseContext();

  return (
    <>
      {error?.message && <Alert className="mb-3" message="Error" description={error.message} type="error" showIcon />}
      <Row gutter={16} className="mb-2">
        <Col span={24}>
          <MemberGroupDimensions
            title="Dimensions"
            dimensions={dimensions}
            timeDimensions={timeDimensions}
            availableMembers={availableDimensions}
            updateTimeMethods={playgroundActionUpdateMethods(updateTimeDimensions)}
            updateQuery={updateQuery}
            query={query}
            isFetchingMeta={isFetchingMeta}
          />
        </Col>
      </Row>
      <Row gutter={16} className="mb-2">
        <Col span={24}>
          <MemberGroupMeasures
            title="Measures"
            members={measures}
            availableMembers={availableMeasures}
            updateMethods={playgroundActionUpdateMethods(updateMeasures) as IUpdateMethods}
            query={query}
            updateQuery={updateQuery}
            isFetchingMeta={isFetchingMeta}
          />
        </Col>
      </Row>
      <Row gutter={16} className="mb-2">
        <Col span={24}>
          <MemberGroupSegments
            title="Segments"
            members={segments}
            availableMembers={availableSegments}
            updateMethods={playgroundActionUpdateMethods(updateSegments)}
            query={query}
            updateQuery={updateQuery}
            isFetchingMeta={isFetchingMeta}
          />
        </Col>
      </Row>
      <Row gutter={16} className="mb-2">
        <Col span={24}>
          <MemberGroupTime
            title="Time"
            members={timeDimensions}
            availableMembers={availableTimeDimensions}
            updateMethods={playgroundActionUpdateMethods(updateTimeDimensions)}
            query={query}
            updateQuery={updateQuery}
            isFetchingMeta={isFetchingMeta}
          />
        </Col>
      </Row>
      <Row gutter={16} className="mb-2">
        <Col span={24}>
          <MemberGroupFilter
            title="Filter"
            members={filters}
            availableMembers={availableDimensions.concat(availableMeasures as any)}
            updateMethods={playgroundActionUpdateMethods(updateFilters)}
            query={query}
            updateQuery={updateQuery}
            isFetchingMeta={isFetchingMeta}
          />
        </Col>
      </Row>
      <Row gutter={16} className="mb-2">
        <Col span={14}>
          <OrderGroup
            title="Order"
            timeDimensions={timeDimensions}
            dimensions={dimensions}
            measures={measures}
            query={query}
            updateQuery={updateQuery}
          />
        </Col>
        <Col span={10}>
          <LimitGroup title="Limit" query={query} isQueryPresent={isQueryPresent} updateQuery={updateQuery} />
        </Col>
      </Row>
    </>
  );
}

export default BuilderForm;
