import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dayjs from "dayjs";

const defaultFormat = "DD/MM/YYYY HH:mm:ss";
export const dayjsBrowserTimezone = ({ value, format = defaultFormat }: { value: any; format?: string }) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  return dayjs.utc(value).tz().format(format);
};
