export const PAGE_URL = {
  ROOT: "/",
  FUNNEL_ANALYSIS: "/funnel-analysis",
  PATH_ANALYSIS: "/path-analysis",
  DATA_DRILLER: "/driller-analysis",
  MULTI_STEP_QUERY: "/multi-step-query",
  LOGIN: "/login",
  ERROR: "/error",
  ERROR_403: "/error/403",
  ERROR_401: "/error/401",
  ADVANCED_ANALYTICS: {
    FUNNEL: "/advanced-analytics/funnel",
  },
  WORKSPACE: {
    SAVED_EXPLORATION: "/workspace/saved-exploration",
  },
};
