import { IFlowConfig } from "@src/types/explorer";

export const checkDuplicateName = (name: string, nodes: IFlowConfig | null) => {
  let isDuplicate = false;

  if (!nodes) return isDuplicate;

  for (const key in nodes) {
    if (key === name) {
      isDuplicate = true;
      break;
    }
  }

  return isDuplicate;
};
