import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { ProTable } from "@ant-design/pro-components";
import { ImageField, List, useDrawerForm } from "@refinedev/antd";
import { Button, Popconfirm, Space, Tooltip } from "antd";
import { ProductCreate } from "./create";
import { useLocalStorage } from "@src/hooks/use-localstorage";
import { ProductEdit } from "./edit";
import { useList } from "@refinedev/core";

export const ProductListing: React.FC = () => {
  const { drawerProps, show, saveButtonProps, formProps, close } = useDrawerForm({
    resource: "product",
    action: "create",
    warnWhenUnsavedChanges: false,
  });
  const {
    drawerProps: drawerPropsEdit,
    show: showEdit,
    saveButtonProps: saveButtonPropsEdit,
    formProps: formPropsEdit,
    close: closeEdit,
    formLoading: formLoadingEdit,
  } = useDrawerForm({
    resource: "product",
    action: "edit",
    warnWhenUnsavedChanges: false,
    meta: {
      headers: {
        "X-Fields": "analytic_events,code,icon,name,group",
      },
    },
  });
  const { data, isLoading, isFetching } = useList({
    resource: "product",
    meta: {
      headers: {
        "X-Fields": "analytic_events,code,icon,name,group",
      },
    },
  });
  const dataSource = data?.data;
  const [products, setProducts] = useLocalStorage<any[]>("products", []);
  const onCreate = (product: any) => {
    setProducts([product, ...products]);
  };

  const columns: any = [
    {
      title: "Product",
      dataIndex: "name",
      key: "name",
      render: (value: string, record: any) => {
        return (
          <div className="flex space-x-2 items-center">
            <div className="w-[30px] h-[30px]">
              <ImageField value={record.icon} className="w-full h-full" preview={false} />
            </div>
            <span>{value}</span>
          </div>
        );
      },
    },
    // {
    //   title: "Last modified",
    //   dataIndex: "updated_at",
    //   render: (value: number) => {
    //     return <span>{dayjs(value).format("DD/MM/YYYY HH:mm:ss")}</span>;
    //   },
    // },
    {
      title: "Product code",
      dataIndex: "code",
    },
    {
      title: "Group",
      dataIndex: "group",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_: any, record: any) => {
        return (
          <Space>
            <Tooltip title="Edit">
              <Button icon={<EditOutlined />} size="small" onClick={() => showEdit(record.code)} />
            </Tooltip>

            <Tooltip title="Delete">
              <Popconfirm title="Are you sure delete this exploration?">
                <Button danger icon={<DeleteOutlined />} size="small" />
              </Popconfirm>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  const eventColumns = [
    {
      title: "Event name",
      dataIndex: "event_name",
    },
  ];

  const paramColumns = [
    {
      title: "Parameter name",
      dataIndex: "param_key",
    },
    {
      title: "Parameter type",
      dataIndex: "param_type",
    },
  ];

  return (
    <>
      <ProductCreate
        onCreate={onCreate}
        drawerProps={drawerProps}
        saveButtonProps={saveButtonProps}
        formProps={formProps}
        close={close}
      />
      <ProductEdit
        drawerProps={drawerPropsEdit}
        saveButtonProps={saveButtonPropsEdit}
        formProps={formPropsEdit}
        close={closeEdit}
        isLoading={formLoadingEdit}
      />
      <List
        canCreate
        createButtonProps={{
          onClick: () => show(),
        }}
      >
        <ProTable
          rowKey={"code"}
          columns={columns}
          dataSource={dataSource}
          loading={isLoading || isFetching}
          search={false}
          options={false}
          pagination={{
            pageSize: 10,
          }}
          expandable={{
            expandedRowRender: (record) => {
              return (
                <ProTable
                  rowKey={"event_name"}
                  search={false}
                  options={false}
                  columns={eventColumns}
                  dataSource={record.analytic_events}
                  pagination={false}
                  expandable={{
                    expandedRowRender: (record: any) => {
                      return (
                        <ProTable
                          rowKey={"param_key"}
                          columns={paramColumns}
                          dataSource={record.event_params}
                          search={false}
                          options={false}
                          pagination={false}
                        />
                      );
                    },
                  }}
                />
              );
            },
          }}
        />
      </List>
    </>
  );
};
