import React from "react";
import { Form } from "antd";
import CodeEditor from "@src/components/editor/code-editor";
import { FormInstance } from "antd/lib/form/Form";
import { useMultiStepQuery } from "@src/contexts/multi-step-query-context";

type IProps = {
  form: FormInstance;
};

function DbtExplorer({ form }: IProps) {
  const { explorerNode } = useMultiStepQuery();
  const templatedQuery = explorerNode?.model_config.query || "";
  return (
    <>
      <div className="border-[#E0E0E0] dark:border-[#2b2b2b] border-0 border-b-[1px] border-solid mb-3 pb-1 font-bold">
        SQL query
      </div>
      <Form.Item name="sql" rules={[{ required: true, message: "Please input sql" }]}>
        <CodeEditor
          language="sql"
          handleEditorChange={(values) => form.setFieldsValue({ sql: values })}
          code={templatedQuery}
        />
      </Form.Item>
    </>
  );
}

export default DbtExplorer;
