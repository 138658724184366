import React from "react";
import { Radio, message } from "antd";

// components
import CodeEditor from "@src/components/editor/code-editor";
import BuilderForm from "./builder-form";

// contexts
import { useWarehouseContext } from "@src/contexts/warehouse-context";
import { useMultiStepQuery } from "@src/contexts/multi-step-query-context";
import { useAppContext } from "@src/contexts/app-context";

function WareHouseExplorer() {
  const [messageApi, contextHolder] = message.useMessage();
  const { product } = useAppContext();
  const { explorerNode, updateMethodsNode } = useMultiStepQuery();
  const { query, updateQuery } = useWarehouseContext();
  const builderType = updateMethodsNode.getBuilderType();

  const code = {
    ...{
      json_query: query || {},
      product_code: explorerNode?.model_config.product_code || product?.productCode || "",
    },
  };

  function handleEditorChange(values: string | undefined) {
    try {
      const parsedValues = JSON.parse(values || "");
      updateQuery(parsedValues.json_query);
    } catch (_) {
      messageApi.error("Invalid JSON format");
    }
  }

  return (
    <React.Fragment>
      {contextHolder}

      <div className="border-[#E0E0E0] dark:border-[#2b2b2b] border-0 border-b-[1px] border-solid mb-3 pb-1 px-2 flex justify-between items-center">
        <div className="w-full">
          <b>Query builder</b>
        </div>
        <div className="flex items-center justify-end w-full">
          <Radio.Group
            size="small"
            value={builderType}
            onChange={(e) => updateMethodsNode.setBuilderType(e.target.value)}
          >
            <Radio.Button value="QUERY_BUILDER">Builder</Radio.Button>
            <Radio.Button value="JSON_EDITOR">JSON Editor</Radio.Button>
          </Radio.Group>
        </div>
      </div>

      <div className={`${builderType === "QUERY_BUILDER" ? "block" : "hidden"}`}>
        <BuilderForm />
      </div>
      <div key={Boolean(explorerNode).toString()} className={`${builderType === "JSON_EDITOR" ? "block" : "hidden"}`}>
        {Object.keys(code.json_query).length > 0 && (
          <CodeEditor code={JSON.stringify(code, null, 2)} handleEditorChange={handleEditorChange} />
        )}
      </div>
    </React.Fragment>
  );
}

export default WareHouseExplorer;
