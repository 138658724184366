import { css } from "@emotion/react";
import { ImageField } from "@refinedev/antd";
import { Card, Form, FormInstance, Input, Select } from "antd";
import { AnalyticEventForm } from "./analytic-events-form";
import { groupProductOps } from "@src/types/param";
import AmanotesIcon from "@src/assets/images/amanotes_icon.png";

interface Props {
  form: FormInstance<object>;
}

export const GeneralBodyForm: React.FC<Props> = ({ form }) => {
  const imageUrl = Form.useWatch("icon", form);

  return (
    <>
      <Card title="General information" size="small">
        <div
          className="flex space-x-2"
          css={css`
            > div.ant-form-item {
              width: 50%;
            }
          `}
        >
          <Form.Item
            name={"name"}
            label="Product name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Enter product name" />
          </Form.Item>
          <Form.Item
            name={"code"}
            label="Product code"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Enter product code" />
          </Form.Item>
        </div>
        <div className="flex space-x-2">
          <Form.Item
            name={"group"}
            label={"Group"}
            rules={[
              {
                required: true,
              },
            ]}
            className="w-1/5"
          >
            <Select options={groupProductOps} />
          </Form.Item>
          <Form.Item
            name={"icon"}
            label="Icon"
            rules={[
              {
                required: true,
              },
            ]}
            className="w-4/5"
          >
            <Input placeholder="Enter icon url" />
          </Form.Item>
          <div className="w-[6%]">
            <Form.Item label={<span className="hidden">Preview</span>}>
              <ImageField preview={false} width={30} height={30} value={imageUrl || AmanotesIcon} />
            </Form.Item>
          </div>
        </div>
      </Card>
      <Card title="Analytic events" size="small" className="mt-3">
        <AnalyticEventForm form={form} />
      </Card>
    </>
  );
};
