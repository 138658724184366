import { DownOutlined } from "@ant-design/icons";
import { IProduct } from "@src/types/user";
import { ProductAutoComplete } from "./autocomplete-product";
import clsx from "clsx";
import React from "react";
import { Button } from "antd";
import { getModeOfLocation } from "@src/util/fb-exploration/get-mode-of-location";

interface AutoCompleteWrapperProps {
  setProduct: (value: IProduct) => void;
  product?: IProduct;
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
}
export const AutoCompleteWrapper: React.FC<AutoCompleteWrapperProps> = ({
  setProduct,
  product,
  isEditing,
  setIsEditing,
}) => {
  const { isEditMode } = getModeOfLocation();
  return (
    <div className="">
      {!isEditing && product ? (
        <Button
          type="text"
          className={clsx("flex items-center relative z-10 cursor-pointer p-0 ")}
          onClick={() => {
            if (isEditMode) return;
            setIsEditing(true);
          }}
        >
          <div className="w-[35px] h-[35px] relative mr-2">
            <img
              className="rounded-lg absolute top-0 left-0 h-full w-full object-cover"
              src={product?.icon}
              alt="Product application"
            />
          </div>
          <div className="text-black mr-2 text-[13px]">
            {product?.name} - {product?.productCode}
          </div>
          {!isEditMode && <DownOutlined />}
        </Button>
      ) : (
        <ProductAutoComplete
          setProduct={setProduct}
          product={product}
          setIsEditing={setIsEditing}
          isEditing={isEditing}
        />
      )}
    </div>
  );
};
