import { config } from "@src/config";
import { DATE_RANGES } from "@src/config/query-builder";
import { DatePicker, Form, Select } from "antd";
import React from "react";

const { RangePicker } = DatePicker;

export const TimeRangeFilter: React.FC<any> = () => {
  const timeRangeWatch = Form.useWatch("time_range");

  const options = DATE_RANGES.map((m) => {
    return {
      label: m.title || m.value,
      value: m.value,
    };
  });
  return (
    <div className="ml-2">
      <Form.Item label="Time Range" name="time_range" required className="mb-1">
        <Select
          placeholder="Select time range"
          options={options}
          style={{
            width: 250,
          }}
          optionRender={(item) => {
            return item.label;
          }}
        />
      </Form.Item>
      {timeRangeWatch === "custom" && (
        <Form.Item name="custom_time_range" className="mb-0">
          <RangePicker style={{ width: 250 }} />
        </Form.Item>
      )}
    </div>
  );
};
