import React from "react";

import { ThemedLayoutContextProvider } from "@refinedev/antd";

import { Grid, Layout as AntdLayout } from "antd";

import { ThemedHeaderV2 } from "./header";
import { Sider } from "./sider";
import { Footer } from "antd/es/layout/layout";
import { config } from "@src/config";
import { HeartOutlined } from "@ant-design/icons";

export const Layout: React.FC<React.PropsWithChildren> = ({ children }) => {
  const breakpoint = Grid.useBreakpoint();
  const isSmall = typeof breakpoint.sm === "undefined" ? true : breakpoint.sm;

  return (
    <ThemedLayoutContextProvider initialSiderCollapsed>
      <AntdLayout hasSider style={{ minHeight: "100vh" }}>
        <Sider />
        <AntdLayout>
          <ThemedHeaderV2 />
          <AntdLayout.Content
            style={{
              padding: isSmall ? 32 : 16,
            }}
          >
            {children}
          </AntdLayout.Content>
          <Footer
            className="border-[#E0E0E0] dark:border-[#2b2b2b] border-0 border-t-[1px] border-solid fixed bottom-0 w-full"
            style={{
              textAlign: "center",
              padding: 5,
              fontSize: 12,
            }}
          >
            ©2024, SAT {config.VERSION} with <HeartOutlined style={{ color: "#f00" }} /> by Amanotes
          </Footer>
        </AntdLayout>
      </AntdLayout>
    </ThemedLayoutContextProvider>
  );
};
