import React, { ReactPortal } from "react";
import ReactDOM from "react-dom";

type IProps = {
  children: React.ReactNode;
  containerId: string;
  disabled?: boolean;
};

export const Portal: React.FC<IProps> = ({ containerId, ...props }) => {
  const [portal, setPortal] = React.useState<ReactPortal | any | null>(null);

  React.useEffect(() => {
    if (props.disabled) return;
    const inject = (): boolean => {
      const el = document.getElementById(containerId);
      if (!el) {
        return false;
      }

      setPortal(ReactDOM.createPortal(props.children, el));
      return true;
    };

    if (!inject()) {
      // DD: Add this in via timeout when containerId is still rendering
      const timer = setTimeout(inject, 10);

      return (): void => {
        clearTimeout(timer);
      };
    }

    return undefined;
  }, [containerId, props.children, props.disabled]);

  return props.disabled ? props.children : portal;
};
