import React from "react";
import { DeploymentUnitOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";

// types
import { IChart } from "@src/types/chart";

import { AxesPivotVisualization } from "./axes-pivot-visualization";

type IProps = {
  layout: IChart;
  setPivotConfig?: React.Dispatch<any>;
};

function PivotGroupVisualization({ layout, setPivotConfig }: IProps) {
  const pivotRef = React.useRef<any>(null);
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button icon={<DeploymentUnitOutlined />} size="small" onMouseDown={showModal}>
        Pivot
      </Button>
      <Modal
        title="Pivot"
        className="modal-amanotes"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
          disabled: true,
        }}
      >
        <div className="pivot-popover">
          <div data-testid="pivot-popover" className=" w-full">
            <AxesPivotVisualization ref={pivotRef} layout={layout} setPivotConfig={setPivotConfig} />
          </div>
        </div>
      </Modal>
    </>
  );
}

export default PivotGroupVisualization;
