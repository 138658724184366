type IProps = {
  sourceIndex: number;
  sourceAxis: string;
  destinationIndex: number;
  destinationAxis: string;
  pivotOver: any;
};

export const movePivotOrder = ({ pivotOver, sourceAxis, sourceIndex, destinationAxis, destinationIndex }: IProps) => {
  const nextPivotOver = {
    ...pivotOver,
    x: [...pivotOver.x],
    y: [...pivotOver.y],
  };
  const id = pivotOver[sourceAxis][sourceIndex];
  const lastIndex = nextPivotOver[destinationAxis].length - 1;
  let _destinationIndex = destinationIndex;

  if (id === "measures") {
    _destinationIndex = lastIndex + 1;
  } else if (
    sourceAxis === destinationAxis &&
    _destinationIndex >= lastIndex &&
    nextPivotOver[destinationAxis][lastIndex] === "measures"
  ) {
    _destinationIndex = lastIndex - 1;
  } else if (
    sourceAxis !== destinationAxis &&
    _destinationIndex > lastIndex &&
    nextPivotOver[destinationAxis][lastIndex] === "measures"
  ) {
    _destinationIndex = lastIndex;
  }

  nextPivotOver[sourceAxis].splice(sourceIndex, 1);
  nextPivotOver[destinationAxis].splice(_destinationIndex, 0, id);

  return nextPivotOver;
};
